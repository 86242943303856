import { MAXES, STEPS } from "constants/search";
import { MINS } from "constants/search";
import React, { FC } from "react";
import RangeSelector from "../../range-selector";

interface HeightProps {
  handleHeightChange: (height: string[]) => void;
  valmax: number;
  valmin: number;
}

const Height: FC<HeightProps> = ({ handleHeightChange, valmax, valmin }) => {
  return (
    <div>
      <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>Height</h4>
      <RangeSelector
        units="in"
        valmax={valmax}
        valmin={valmin}
        setValues={handleHeightChange}
        min={MINS["Height"]}
        max={MAXES["Height"]}
        step={STEPS["Height"]}
      />
    </div>
  );
};

export default Height;
