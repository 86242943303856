import React from "react";
import { PlayerService } from "services/PlayerService";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import colors from "constants/colors";
import { SAFE_toFixed } from "services/utils";
import { JuniorCollege } from "models/JuniorCollege";

function PopOver(props: {
  player: any;
  skipPosition?: boolean;
  disabledMode?: "pbr_disabled" | "pg_disabled" | "both" | "none";
}) {
  const th = (name) => {
    return (
      <th
        style={{
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          textAlign: "center",
          padding: 4,
        }}
      >
        {name}
      </th>
    );
  };

  const td = (val, forceInt = false) => {
    let stringVal = val || "0";
    if (!forceInt && val && !isNaN(parseFloat(val)) && parseFloat(val) < 1) {
      stringVal = SAFE_toFixed(val, 3);
      stringVal = stringVal.replace(/^0+/, "");
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        {stringVal}
      </td>
    );
  };

  const isPitcher = () => {
    const pitcher = props.player.pitching_stats;
    if (pitcher) {
      return true;
    } else {
      return false;
    }
  };

  const renderBattingStats = (stats: JuniorCollege["batting_stats"]) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <tr>
          {th("G")}
          {th("PA")}
          {th("AB")}
          {th("R")}
          {th("H")}
          {th("2B")}
          {th("3B")}
          {th("HR")}
          {th("RBI")}
          {th("SB")}
          {th("CS")}
          {th("BB")}
          {th("SO")}
          {th("AVG")}
          {th("OBP")}
          {th("SLG")}
        </tr>
        <tr style={{ backgroundColor: colors.lightGray }}>
          {td(stats["games-played"])}
          {td(stats["plate-appearances"])}
          {td(stats["at-bats"])}
          {td(stats.runs)}
          {td(stats.hits)}
          {td(stats["doubles"], true)}
          {td(stats["triples"], true)}
          {td(stats["home-runs"], true)}
          {td(stats.rbis)}
          {td(stats["stolen-bases"])}
          {td(stats["caught-stealing"])}
          {td(stats.walks)}
          {td(stats["strikeouts"])}
          {td(stats.avg)}
          {td(stats.obp)}
          {td(stats.slg)}
        </tr>
      </table>
    );
  };

  const renderPitchingStats = (stats: JuniorCollege["pitching_stats"]) => {
    return isPitcher ? (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <tr>
          {th("W")}
          {th("L")}
          {th("ERA")}
          {th("G")}
          {th("GS")}
          {th("CG")}
          {th("SHO")}
          {th("SV")}
          {th("IP")}
          {th("H")}
          {th("R")}
          {th("ER")}
          {th("HR")}
          {th("BB")}
          {th("SO")}
          {th("HBP")}
          {th("WHIP")}
          {th("BA")}
          {th("2B")}
          {th("3B")}
          {th("SF")}
          {th("SH")}
          {th("WP")}
          {th("BK")}
          {th("K/9")}
        </tr>
        <tr style={{ backgroundColor: colors.lightGray }}>
          {td(stats.wins)}
          {td(stats.losses, true)}
          {td(SAFE_toFixed(stats.era, 2))}
          {td(stats["games"], true)}
          {td(stats["games-started"], true)}
          {td(stats["complete-games"], true)}
          {td(stats.shutouts, true)}
          {td(stats.saves, true)}
          {td(stats["innings-pitched"])}
          {td(stats["hits-allowed"], true)}
          {td(stats["runs-allowed"], true)}
          {td(stats["earned-runs"], true)}
          {td(stats["home-runs-allowed"], true)}
          {td(stats.walks)}
          {td(stats.strikeouts)}
          {td(stats["hit-batters"])}
          {td(stats.whip)}
          {td(stats["batting-average-against"])}
          {td(stats["doubles-allowed"], true)}
          {td(stats["triples-allowed"], true)}
          {td(stats["sacrifice-flies-allowed"], true)}
          {td(stats["sacrifice-hits-allowed"], true)}
          {td(stats["wild-pitches"], true)}
          {td(stats.balks)}
          {td(stats["strikeouts-per-game"])}
        </tr>
      </table>
    ) : (
      <div />
    );
  };

  const renderJuniorCollegeDetails = () => {
    const player = props.player;
    const isPlayerPitcher = isPitcher();

    return isPlayerPitcher
      ? renderPitchingStats(player.pitching_stats)
      : renderBattingStats(player.batting_stats);
  };

  return (
    <>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement={"right"}
        overlay={
          <Popover id={`popover-positioned-right`}>
            {props.player &&
            (props.player.pitching_stats || props.player.batting_stats) ? (
              <>
                <Popover.Content style={{ margin: 0, padding: 0 }}>
                  {renderJuniorCollegeDetails()}
                </Popover.Content>
              </>
            ) : (
              <>
                <Popover.Title
                  as="h3"
                  style={{
                    backgroundColor: "#467DB3",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Player Details Not Found
                </Popover.Title>
                <Popover.Content
                  style={{ margin: "10px", textAlign: "center" }}
                >
                  No additional information available for this player.
                </Popover.Content>
              </>
            )}
          </Popover>
        }
      >
        <Link
          to={`/juniorcollege/${props.player?.external_id}`}
          className="row-p"
          style={{
            color: colors.accent,
            textDecoration: "underline",
            textDecorationColor: colors.accent,
            textDecorationStyle: "solid",
            whiteSpace: "nowrap",
          }}
        >
          {PlayerService.getName(props.player)}
          <span style={{ fontWeight: 700 }}>
            {props.skipPosition || false
              ? ""
              : ` (${props.player?.primary_position}${
                  props.player?.alt_position
                    ? `, ${props.player?.alt_position}`
                    : ""
                })`}
          </span>
        </Link>
      </OverlayTrigger>
    </>
  );
}

export default PopOver;
