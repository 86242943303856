import React, { FC } from "react";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

interface ThrowProps {
  handleThrowSelection: any;
  throwData: string;
}

const Throw: FC<ThrowProps> = ({ handleThrowSelection, throwData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const throws = ["R", "L", "S"];

  const checkboxStyle = (isChecked: boolean) => ({
    appearance: "none" as const,
    WebkitAppearance: "none" as const,
    width: "16px",
    height: "16px",
    border: isChecked ? "2px solid #4285f4" : "2px solid white",
    borderRadius: "3px",
    backgroundColor: isChecked ? "#4285f4" : "transparent",
    position: "relative" as const,
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  });

  const handleThrowSelect = (throwType: string) => {
    const newValue = throwData === throwType ? "" : throwType;
    handleThrowSelection(newValue);
  };

  return (
    <div style={{ marginTop: 10 }}>
      <div
        style={{
          display: "flex",
          color: "white",
          fontSize: 24,
          alignItems: "center",
          gap: 10,
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>Throws</p>
        <div
          style={{
            transform: `rotate(${isOpen ? 0 : -90}deg)`,
            transition: "transform 0.1s ease-in-out",
          }}
        >
          <IoIosArrowDown />
        </div>
      </div>

      {isOpen && (
        <div style={{ marginTop: 10, paddingLeft: 20 }}>
          {throws.map((throwType) => {
            return (
              <div
                key={throwType}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 8,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => handleThrowSelect(throwType)}
              >
                <div style={checkboxStyle(throwData === throwType)}>
                  {throwData === throwType && (
                    <div
                      style={{
                        width: "5px",
                        height: "9px",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        transform: "rotate(45deg)",
                        marginTop: "-2px",
                      }}
                    />
                  )}
                </div>
                <span style={{ fontSize: 16 }}>{throwType}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Throw;
