import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import Stat from "../Stat";
import { STEPS } from "constants/search";
import { MAXESBATTING } from "pages/search-page/components/PortalSearch/AdvancedSearchPortal";

const battingStatKeys = [
  "G",
  "PA",
  "AB",
  "R",
  "H",
  "2B",
  "3B",
  "HR",
  "RBI",
  "SB",
  "CS",
  "BB",
  "SO",
  "AVG",
  "OBP",
  "SLG",
];

const Batting = ({ filters, setFilters }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          color: "white",
          fontSize: 24,
          alignItems: "center",
          gap: 10,
          cursor: "pointer",
          width: "fit-content",
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <p>BATTING STATS</p>
        <div
          style={{
            transform: `rotate(${isExpanded ? 0 : -90}deg)`,
            transition: "transform 0.1s ease-in-out",
          }}
        >
          <IoIosArrowDown />
        </div>
      </div>
      {isExpanded &&
        battingStatKeys.map((stat) => {
          return (
            <Stat
              units={
                stat === "Weight"
                  ? "lbs"
                  : stat === "Height"
                  ? "in"
                  : stat === "Academic Class"
                  ? "collegeClass"
                  : ""
              }
              type={filters[`batting_${stat.toLowerCase()}`].type}
              valmax={filters[`batting_${stat.toLowerCase()}`].max}
              valmin={filters[`batting_${stat.toLowerCase()}`].min}
              setValues={(vals, type) => {
                setFilters((draft) => {
                  draft[`batting_${stat.toLowerCase()}`] = {
                    ...draft[`batting_${stat.toLowerCase()}`],
                    min: vals[0],
                    max: vals[1],
                    active: true,
                  };
                });
                // handleTabSelection(`batting_${stat.toLowerCase()}`, false);
              }}
              setType={(newType) => {
                setFilters((draft) => {
                  draft[`batting_${stat.toLowerCase()}`].type = newType;
                });
              }}
              min={0}
              max={MAXESBATTING[stat]}
              step={stat === "CS" ? 1 : STEPS[stat]}
              title={stat}
            />
          );
        })}
    </div>
  );
};

export default Batting;
