import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { QueryHelper } from "services/QueryHelper";
import { Player } from "models/Player";
import { PlayerService } from "services/PlayerService";
import "./player-page.css";
import { Link } from "react-router-dom";
import StarButton from "shared/star-button";
import { useSelector } from "react-redux";
import { compose } from "recompose";
import {
  isLoaded,
  useFirestore,
  useFirestoreConnect,
  withFirestore,
} from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { AiFillPlusCircle, AiOutlineRight } from "react-icons/ai";

import SharePlayerModal from "../../modals/share-player-modal";
import EditPlayerModal from "../../modals/edit-player-modal";
import PlayAnalysisModal from "modals/play-analysis-modal";
import { PositionReport } from "models/PositionReport";
import ReportCard from "pages/reports-list-page/components/report-card";
import colors from "constants/colors";
import HelpModal from "modals/help-modal";
import ScoutTitle from "shared/scout-title";
import PlayerCalendarEvent from "popovers/player-calendar-event";

import UnderlineButton from "shared/underline-button";
import { JuniorCollege } from "models/JuniorCollege";
import { useMe } from "../../hooks/users";
import NotifyMeModal from "modals/notify-me-modal";
import PlayerScholarshipEditor from "./components/scholarship-editor";
import { SAFE_toFixed } from "services/utils";
import { Row, Column } from "components/basic";
import { CollegePlayerIcon } from "components/smart-icons";

import { PitchAnalysis } from "pages/player-page/synergy-table";

function JuniorCollegePage(props: any) {
  let { id } = useParams();
  const [player, setPlayer] = useState<JuniorCollege | null>(null);
  const [visiblePlay, setVisiblePlay] = useState(null);
  const [visibleGame, setVisibleGame] = useState(null);

  const me: any = useMe();
  const users = useSelector((state: any) => state.firestore.data.users);
  const myReports = useSelector((state: any) => state.firestore.data.myReports);
  const myEvents = useSelector((state: any) => state.firestore.data.myEvents);
  const myProspects = useSelector(
    (state: any) => state.firestore.data.myProspects
  );
  const myRoster = useSelector((state: any) => state.firestore.data.myRoster);
  const history = useHistory();
  const firestore = useFirestore();

  const isPitcher = player?.pitching_stats;

  const DEMO_SHOW_ANALYSIS = !player
    ? false
    : [
        "Will Pearson",
        "Chandler Marsh",
        "Jarvis Evans",
        "Nolan Crisp",
        "Collin Caldwell",
        "Leighton Finley",
        "Dalton Rhadans",
        "Paul Skenes",
        "Riley Cooper",
        "Thatcher Hurd",
      ].includes(`${player?.first_name} ${player?.last_name}`);

  useFirestoreConnect([
    {
      collection: "users",
      doc: me.id,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
    {
      collection: "users",
      doc: me.id,
      subcollections: [{ collection: "roster" }],
      storeAs: "myRoster",
    },
    { collection: "users", where: ["friends", "array-contains", me.id] },
    {
      collection: "users",
      doc: me.id,
      subcollections: [{ collection: "reports" }],
      storeAs: `myReports`,
    },
    {
      collection: "users",
      doc: me.id as unknown as string,
      subcollections: [{ collection: "events" }],
      storeAs: `myEvents`,
    },
  ]);
  const [modalShow, setModalShow] = React.useState(false);
  const [showNotifyModal, setShowNotifyModal] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [fetching, setFetching] = useState(false);

  const fetchDataForURLParams = async () => {
    if (!fetching) {
      setFetching(true);
      let p = await QueryHelper.getJuniorCollegePlayerForExternalId(id);
      if (p && p.data && p.data.length > 0) {
        let players = p.data.filter((item) => item.external_id === id);
        if (players.length > 0) {
          let pl = players[0];
          if (myProspects && Object.keys(myProspects).includes(id)) {
            let pros = myProspects[id];
            setPlayer({
              ...pros,
              ...pl,
              position: pros.position,
            });
          } else {
            setPlayer(pl);
          }

          setFetching(false);
        }
      }
    }
  };

  if (isLoaded(myProspects) && player === null) {
    fetchDataForURLParams();
  } else if (player && player.external_id && player.external_id !== id) {
    setPlayer(null);
  }

  const sortedProspects = () => {
    if (!isLoaded(myProspects)) {
      return [];
    }

    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null && item.active);
  };

  const sortedFriends = () => {
    if (!isLoaded(users)) {
      return [];
    }

    let friends = users
      ? Object.keys(users).map((key) =>
          users ? { ...users[key], id: key } : null
        )
      : [];

    return friends.filter((item) => item !== null);
  };

  const sortedReports = (): PositionReport[] => {
    if (!isLoaded(myReports)) {
      return [];
    }

    let reports = myReports
      ? Object.keys(myReports).map((key) =>
          myReports ? { ...myReports[key], id: key } : null
        )
      : [];

    return reports.filter(
      (item) =>
        item !== null && item.id && item.playerName && item.playerId === id
    );
  };

  const sortedEvents = () => {
    if (!isLoaded(myEvents)) {
      return [];
    }

    let events = myEvents
      ? Object.keys(myEvents).map((key) =>
          myEvents ? { ...myEvents[key], id: key } : null
        )
      : [];

    events = events.filter(
      (item) =>
        item.eventDate &&
        item.eventDate.seconds &&
        (item.addedProspectIds || []).includes(id)
    );

    return events;
  };

  const renderReports = () => {
    return sortedReports().length === 0 ? (
      <div style={{ minHeight: 100 }}>
        <p
          style={{
            fontStyle: "italic",
            opacity: 0.6,
            marginTop: 10,
            paddingLeft: 20,
          }}
        >
          No reports.
        </p>
      </div>
    ) : (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          paddingTop: 20,
          paddingLeft: 10,
          maxWidth: "calc(100vw - 390px)",
          overflow: "scroll",
        }}
      >
        {(sortedReports() || []).map((item, index) => (
          <ReportCard report={item} small={true} />
        ))}
      </div>
    );
  };

  const renderUpcomingEvents = () => {
    return sortedEvents().length === 0 ? (
      <div style={{ minHeight: 100 }}>
        <p
          style={{
            fontStyle: "italic",
            opacity: 0.6,
            marginTop: 10,
            paddingLeft: 20,
          }}
        >
          No upcoming games.
        </p>
      </div>
    ) : (
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          paddingTop: 20,
          paddingLeft: 10,
          maxWidth: "calc(100vw - 390px)",
          overflow: "scroll",
        }}
      >
        {(sortedEvents() || []).map((item, index) => (
          <PlayerCalendarEvent
            event={item}
            prospects={sortedProspects()}
            onViewCalendar={() => {
              history.push("/calendar");
            }}
          />
        ))}
      </div>
    );
  };

  const onRoster = () => {
    return (
      player &&
      (sortedRoster() || [])
        .map((pl) => pl.external_id)
        .includes(player.external_id)
    );
  };

  const sortedRoster = () => {
    if (!isLoaded(myRoster)) {
      return [];
    }

    let prospects = myRoster
      ? Object.keys(myRoster).map((key) =>
          myRoster ? { ...myRoster[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null);
  };

  const addToRoster = async () => {
    if (player && !onRoster()) {
      firestore
        .collection("users")
        .doc(me.id as unknown as string)
        .collection("roster")
        .doc(player.external_id)
        .set({
          ...player,
          scholarshipByYear: {},
          financialAidByYear: {},
          rosterNumber: sortedRoster().length + 1,
        });
    }
  };

  const removeFromRoster = async () => {
    if (player) {
      firestore
        .collection("users")
        .doc(me.id as unknown as string)
        .collection("roster")
        .doc(player.external_id)
        .delete();
    }
  };

  const renderNameRow = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
        }}
      >
        <h2 style={{ fontFamily: "Raleway", color: "#fff" }}>
          {player ? player.name : ""}
        </h2>
        <div>
          <StarButton
            player={player}
            gold={true}
            buttonStyles={{ marginLeft: 12, marginTop: -10 }}
          />
        </div>

        <div style={{ flex: 1 }} />

        <div
          style={{
            flexDirection: "row",
            display: "flex",
            marginRight: 6,
          }}
        >
          {/* {saved ? (
            <UnderlineButton
              text={"edit"}
              onClick={() => setShowEdit(true)}
              color={"#fff"}
            />
          ) : (
            <div />
          )} */}
          <UnderlineButton
            text={"notify me"}
            onClick={() => setShowNotifyModal(true)}
            color={"#fff"}
          />
          <UnderlineButton
            text={"share"}
            onClick={() => setModalShow(true)}
            color={"#fff"}
          />

          {onRoster() ? (
            <UnderlineButton
              text={"remove from roster"}
              onClick={removeFromRoster}
              color={"#fff"}
            />
          ) : (
            <UnderlineButton
              text={"add to roster"}
              onClick={addToRoster}
              color={"#fff"}
            />
          )}
          <ScoutTitle
            text=""
            withHelp={true}
            light={true}
            onClick={() => setShowHelp(true)}
          />
        </div>
      </div>
    );
  };

  const renderGeneralInfo = () => {
    return player ? (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="middle-text-container">
          <p className="middle-text-small">
            Class:
            <p className="middle-text-bold">{player ? player.year : ""}</p>
          </p>
          <p className="middle-text-small">
            Position:
            <p className="middle-text-bold">
              {player
                ? player.position?.length > 0
                  ? player.position.join(" / ")
                  : "N/A"
                : "N/A"}
            </p>
          </p>
          <p className="middle-text-small">
            Team:
            <p className="middle-text-bold">
              {player && player.team !== "" ? player.team : "N/A"}
            </p>
          </p>
        </div>

        <div className="middle-text-container">
          <p className="middle-text-small">
            Height:
            <p className="middle-text-bold">
              {player && player.height !== "" ? player.height : "N/A"}
            </p>
          </p>
          <p className="middle-text-small">
            Weight:
            <p className="middle-text-bold">
              {player && player.weight !== null ? `${player.weight}lbs` : "N/A"}
            </p>
          </p>
          <p className="middle-text-small">
            League:
            <p className="middle-text-bold">
              {player && player.league !== null ? `${player.league}` : "N/A"}
            </p>
          </p>
        </div>
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  };

  const th = (name) => {
    return (
      <th
        style={{
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          textAlign: "center",
          padding: 4,
        }}
      >
        {name}
      </th>
    );
  };

  const td = (val, forceInt = false) => {
    let stringVal = val || "0";
    if (!forceInt && val && !isNaN(parseFloat(val)) && parseFloat(val) < 1) {
      stringVal = SAFE_toFixed(val, 3);
      stringVal = stringVal.replace(/^0+/, "");
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        {stringVal}
      </td>
    );
  };

  const renderBattingStats = (stats: JuniorCollege["batting_stats"]) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <tr>
          {th("G")}
          {th("PA")}
          {th("AB")}
          {th("R")}
          {th("H")}
          {th("2B")}
          {th("3B")}
          {th("HR")}
          {th("RBI")}
          {th("SB")}
          {th("CS")}
          {th("BB")}
          {th("SO")}
          {th("AVG")}
          {th("OBP")}
          {th("SLG")}
        </tr>
        <tr style={{ backgroundColor: colors.lightGray }}>
          {td(stats["games-played"])}
          {td(stats["plate-appearances"])}
          {td(stats["at-bats"])}
          {td(stats.runs)}
          {td(stats.hits)}
          {td(stats["doubles"], true)}
          {td(stats["triples"], true)}
          {td(stats["home-runs"], true)}
          {td(stats.rbis)}
          {td(stats["stolen-bases"])}
          {td(stats["caught-stealing"])}
          {td(stats.walks)}
          {td(stats["strikeouts"])}
          {td(stats.avg)}
          {td(stats.obp)}
          {td(stats.slg)}
        </tr>
      </table>
    );
  };

  const renderPitchingStats = (stats: JuniorCollege["pitching_stats"]) => {
    return isPitcher ? (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <tr>
          {th("W")}
          {th("L")}
          {th("ERA")}
          {th("G")}
          {th("GS")}
          {th("CG")}
          {th("SHO")}
          {th("SV")}
          {th("IP")}
          {th("H")}
          {th("R")}
          {th("ER")}
          {th("HR")}
          {th("BB")}
          {th("SO")}
          {th("HBP")}
          {th("WHIP")}
          {th("BA")}
          {th("2B")}
          {th("3B")}
          {th("SF")}
          {th("SH")}
          {th("WP")}
          {th("BK")}
          {th("K/9")}
        </tr>
        <tr style={{ backgroundColor: colors.lightGray }}>
          {td(stats.wins)}
          {td(stats.losses, true)}
          {td(SAFE_toFixed(stats.era, 2))}
          {td(stats["games"], true)}
          {td(stats["games-started"], true)}
          {td(stats["complete-games"], true)}
          {td(stats.shutouts, true)}
          {td(stats.saves, true)}
          {td(stats["innings-pitched"])}
          {td(stats["hits-allowed"], true)}
          {td(stats["runs-allowed"], true)}
          {td(stats["earned-runs"], true)}
          {td(stats["home-runs-allowed"], true)}
          {td(stats.walks)}
          {td(stats.strikeouts)}
          {td(stats["hit-batters"])}
          {td(stats.whip)}
          {td(stats["batting-average-against"])}
          {td(stats["doubles-allowed"], true)}
          {td(stats["triples-allowed"], true)}
          {td(stats["sacrifice-flies-allowed"], true)}
          {td(stats["sacrifice-hits-allowed"], true)}
          {td(stats["wild-pitches"], true)}
          {td(stats.balks)}
          {td(stats["strikeouts-per-game"])}
        </tr>
      </table>
    ) : (
      <div />
    );
  };

  const renderStats = () => {
    if (player) {
      if (player.batting_stats || player.pitching_stats) {
        return (
          <div
            style={{
              marginLeft: 15,
              marginTop: 10,
              flex: 1,

              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: 24,
                color: colors.lightBlue,
                fontWeight: "bold",
              }}
            >
              {isPitcher ? "PITCHING STATS" : "BATTING STATS"}
            </p>

            {isPitcher
              ? renderPitchingStats(player.pitching_stats)
              : renderBattingStats(player.batting_stats)}
          </div>
        );
      } else {
        return (
          <div
            style={{
              marginLeft: 15,
              marginTop: 10,
              flex: 1,

              width: "100%",
            }}
          >
            <p
              style={{
                fontSize: 24,
                color: colors.lightBlue,
                fontWeight: "bold",
              }}
            >
              {isPitcher ? "PITCHING STATS" : "BATTING STATS"}
            </p>

            {isPitcher
              ? renderPitchingStats(player.pitching_stats)
              : renderBattingStats(player.batting_stats)}
          </div>
        );
      }
    } else {
      return <div />;
    }
  };

  const renderPhoto = () => {
    return (
      <div
        style={{
          borderRadius: 9,
          flexDirection: "column",
          display: "flex",
          marginLeft: 15,
          marginTop: -85,
        }}
      >
        <div style={{ paddingLeft: 200, zIndex: 100 }}>
          <CollegePlayerIcon
            color="white"
            style={{ fontSize: 30 }}
            junior_college={true}
          />
        </div>
        <div
          className="profile-picture"
          style={{
            marginTop: -40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: colors.accent,
          }}
        >
          <p
            style={{
              color: colors.white,
              fontSize: 100,
              fontWeight: 800,
            }}
          >
            {player ? PlayerService.getInitials(player) : ""}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        marginLeft: "1rem",
        // padding: "1rem",
        backgroundColor: "#fff",
        display: "flex",
        minHeight: "90vh",
        flexDirection: "column",
        borderColor: colors.lightBlue,
        borderWidth: 5,
        borderStyle: "solid",
      }}
    >
      <div
        style={{
          backgroundColor: colors.lightBlue,
          height: 130,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingLeft: 275,
        }}
      >
        {renderNameRow()}
      </div>

      <div
        style={{
          flexDirection: "column",
          display: "flex",

          flex: 1,
        }}
      >
        <div
          style={{
            flexDirection: "row",
            display: "flex",

            flex: 1,
          }}
        >
          {renderPhoto()}
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              flex: 1,
              marginRight: 20,
            }}
          >
            {renderGeneralInfo()}
            {renderStats()}
          </div>
        </div>

        {DEMO_SHOW_ANALYSIS && (
          <Column style={{ paddingLeft: "16px" }}>
            <p
              style={{
                fontSize: 24,
                color: colors.lightBlue,
                fontWeight: "bold",
              }}
            >
              ANALYSIS
            </p>
            <PitchAnalysis
              player={player}
              setVisiblePlay={setVisiblePlay}
              setVisibleGame={setVisibleGame}
            />
          </Column>
        )}

        <div className="reports-container">
          <div
            className="reports-top-row"
            style={{ flexDirection: "row", display: "flex" }}
          >
            <p className="reports-title">REPORTS</p>
            <Link
              to={`/create-report/${id}`}
              style={{
                marginTop: -6,
                color: colors.accent,
                fontSize: 25,
                backgroundColor: "transparent",
                padding: 6,
                marginLeft: 3,
                borderWidth: 0,
                boxShadow: "none",
              }}
            >
              <AiFillPlusCircle />
            </Link>
          </div>
          {renderReports()}
        </div>

        <div className="upcoming-container">
          <div
            className="upcoming-top-row"
            style={{ flexDirection: "row", display: "flex" }}
          >
            <p className="upcoming-title">UPCOMING GAMES</p>
            <Link
              to={`/calendar?player=${id}`}
              style={{
                marginTop: -6,
                color: colors.accent,
                fontSize: 25,
                backgroundColor: "transparent",
                padding: 6,
                marginLeft: 3,
                borderWidth: 0,
                boxShadow: "none",
              }}
            >
              <AiFillPlusCircle />
            </Link>
          </div>
          {renderUpcomingEvents()}
        </div>
      </div>

      <SharePlayerModal
        show={modalShow}
        friends={sortedFriends()}
        onHide={() => setModalShow(false)}
        me={me}
        player={player}
      />

      <HelpModal
        show={showHelp}
        kind={"PLAYER PROFILE"}
        onHide={() => setShowHelp(false)}
      />

      <PlayAnalysisModal
        show={!!visiblePlay}
        onHide={() => {
          setVisiblePlay(null);
          setVisibleGame(null);
        }}
        play={visiblePlay}
        setPlay={setVisiblePlay}
        game={visibleGame}
      />

      {player ? (
        <NotifyMeModal
          show={showNotifyModal}
          onHide={() => setShowNotifyModal(false)}
          //@ts-ignore
          currentPlayer={player}
        />
      ) : (
        <div />
      )}

      {player ? (
        <EditPlayerModal
          show={showEdit}
          onHide={() => setShowEdit(false)}
          editPlayer={(pl) => setPlayer(pl)}
          player={player}
        />
      ) : (
        <div />
      )}
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(JuniorCollegePage);
