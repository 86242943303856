import { MAXES, STEPS } from "constants/search";
import { MINS } from "constants/search";
import React, { FC } from "react";
import RangeSelector from "../../range-selector";

interface WeightProps {
  handleWeightChange: (weight: string[]) => void;
  valmax: number;
  valmin: number;
}

const Weight: FC<WeightProps> = ({ handleWeightChange, valmax, valmin }) => {
  return (
    <div>
      <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>Weight</h4>
      <RangeSelector
        units="lbs"
        valmax={valmax}
        valmin={valmin}
        setValues={handleWeightChange}
        min={MINS["Weight"]}
        max={MAXES["Weight"]}
        step={STEPS["Weight"]}
      />
    </div>
  );
};

export default Weight;
