import React from "react";
import { PlayerService } from "services/PlayerService";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import colors from "constants/colors";
import { SAFE_toFixed } from "services/utils";

function PopOver(props: {
  player: any;
  skipPosition?: boolean;
  disabledMode?: "pbr_disabled" | "pg_disabled" | "both" | "none";
}) {
  const th = (name) => {
    return (
      <th
        style={{
          borderCollapse: "collapse",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          textAlign: "center",
          padding: 4,
          whiteSpace: "nowrap",
        }}
      >
        {name}
      </th>
    );
  };

  const td = (val, key?) => {
    let stringVal = val;
    let paddingDefault = "5px 14PX";
    if (val && !isNaN(parseFloat(val)) && parseFloat(val) < 1) {
      stringVal = SAFE_toFixed(val, 3);
      stringVal = stringVal.replace(/^0+/, "");
    }

    if (key && key === "TM" && stringVal.length > 10) {
      stringVal = stringVal.substring(0, 12) + "...";
    }

    if ((key && key === "LG") || key === "TM") {
      paddingDefault = "4px 15px";
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: paddingDefault,
          whiteSpace: "nowrap",
        }}
      >
        {stringVal || "N/A"}
      </td>
    );
  };

  const tdForPitching = (val, key?) => {
    let stringVal = val;
    let strFirst = val?.split(".")[0] || "";
    let paddingDefault = "5px 14px";

    if (
      key === "W" ||
      key === "L" ||
      key === "G" ||
      key === "GS" ||
      key === "GF" ||
      key === "CG" ||
      key === "SHO" ||
      key === "SV" ||
      key === "H" ||
      key === "R" ||
      key === "ER" ||
      key === "HR" ||
      key === "BB" ||
      key === "IBB"
    ) {
      if (parseInt(strFirst)) {
        const keyInt = parseInt(val);
        stringVal = keyInt.toString();
      }
    }
    if (key === "W-L%") {
      if (!isNaN(parseFloat(val))) {
        const parsedVal = parseFloat(val);
        stringVal =
          parsedVal === 0 ? "0" : parsedVal.toFixed(3).replace(/^0\./, ".");
      }
    }
    if (key === "ERA" || key === "RA") {
      stringVal = parseFloat(val).toFixed(2).replace(/^0\./, "0");
      if (stringVal.startsWith("0.")) {
        stringVal = "0";
      }
    }
    if (key === "IP") {
      stringVal = parseFloat(val).toFixed(1).replace(/^0\./, "0");
      if (stringVal.startsWith("0.")) {
        stringVal = "0";
      }
    }
    if (key && key === "TM" && stringVal.length > 10) {
      stringVal = stringVal.substring(0, 12) + "...";
    }
    if ((key && key === "LG") || key === "TM") {
      paddingDefault = "4px 15px";
    }
    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: paddingDefault,
          whiteSpace: "nowrap",
        }}
      >
        {stringVal || "N/A"}
      </td>
    );
  };

  const tdForBatting = (val, key) => {
    let stringVal = val;
    let strFirst = val?.split(".")[0] || "";
    let paddingDefault = "5px 14px";

    if (key && key === "TM" && stringVal.length > 10) {
      stringVal = stringVal.substring(0, 12) + "...";
    }

    if ((key && key === "LG") || key === "TM") {
      paddingDefault = "4px 15px";
    }

    if (
      key === "G" ||
      key === "PA" ||
      key === "AB" ||
      key === "R" ||
      key === "H" ||
      key === "2B" ||
      key === "3B" ||
      key === "HR" ||
      key === "RBI" ||
      key === "SB" ||
      key === "CS" ||
      key === "BB" ||
      key === "SO" ||
      key === "TB" ||
      key === "GDP" ||
      key === "HBP" ||
      key === "SH" ||
      key === "SF" ||
      key === "IBB"
    ) {
      if (parseInt(strFirst)) {
        const keyInt = parseInt(val);
        stringVal = keyInt.toString();
      }
    }

    if (key === "BA" || key === "OBP" || key === "SLG" || key === "OPS") {
      if (!isNaN(parseFloat(val))) {
        const parsedVal = parseFloat(val);
        stringVal =
          parsedVal === 0 ? "0" : parsedVal.toFixed(3).replace(/^0\./, ".");
      }
    }

    return (
      <td
        style={{
          borderCollapse: "collapse",
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: paddingDefault,
          whiteSpace: "nowrap",
        }}
      >
        {stringVal || "N/A"}
      </td>
    );
  };

  const isPitcher = () => {
    const pitcher = props.player.playerDetails?.sr_position
      .toLowerCase()
      .includes("pitcher");
    if (pitcher) {
      return true;
    } else {
      return false;
    }
  };

  const renderPitchingStats = (stats: any) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <thead>
          <tr>
            {th("Year")}
            {th("TM")}
            {th("LG")}
            {th("G")}
            {th("W")}
            {th("L")}
            {th("W-L%")}
            {th("ERA")}
            {th("RA")}
            {th("GS")}
            {th("GF")}
            {th("CG")}
            {th("SHO")}
            {th("SV")}
            {th("IP")}
            {th("H")}
            {th("R")}
            {th("ER")}
            {th("HR")}
            {th("BB")}
            {th("IBB")}
            {th("SO")}
          </tr>
        </thead>
        <tbody>
          {stats.map((stat) => {
            return (
              <tr style={{ backgroundColor: colors.lightGray }}>
                {tdForPitching(stat.year.toString(), "year")}
                {tdForPitching(stat.team || "N/A", "TM")}
                {tdForPitching(stat.league, "LG")}
                {tdForPitching(stat["games-played"]?.toString(), "G")}
                {tdForPitching(stat.wins?.toString(), "W")}
                {tdForPitching(stat.losses?.toString(), "L")}
                {tdForPitching(stat["win-loss-percentage"]?.toString(), "W-L%")}
                {tdForPitching(stat["earned-run-average"]?.toString(), "ERA")}
                {tdForPitching(stat["run-average"]?.toString(), "RA")}
                {tdForPitching(stat["games-started"]?.toString(), "GS")}
                {tdForPitching(stat["games-finished"]?.toString(), "GF")}
                {tdForPitching(stat["complete-game"]?.toString(), "CG")}
                {tdForPitching(stat.shutouts?.toString(), "SHO")}
                {tdForPitching(stat.saves?.toString(), "SV")}
                {tdForPitching(stat["innings-pitched"]?.toString(), "IP")}
                {tdForPitching(stat["hits-allowed"]?.toString(), "H")}
                {tdForPitching(stat["runs-allowed"]?.toString(), "R")}
                {tdForPitching(stat["earned-runs-allowed"]?.toString(), "ER")}
                {tdForPitching(stat["home-runs-per-nine"]?.toString(), "HR")}
                {tdForPitching(stat["bases-on-balls"]?.toString(), "BB")}
                {tdForPitching(
                  stat["intentional-bases-on-balls"]?.toString(),
                  "IBB"
                )}
                {tdForPitching(stat["strikeouts"]?.toString(), "SO")}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const renderBattingStats = (stats: any) => {
    return (
      <table style={{ width: "100%", border: "1px solid #467DB3" }}>
        <thead>
          <tr>
            {th("Year")}
            {th("TM")}
            {th("LG")}
            {th("G")}
            {th("PA")}
            {th("AB")}
            {th("R")}
            {th("H")}
            {th("2B")}
            {th("3B")}
            {th("HR")}
            {th("RBI")}
            {th("SB")}
            {th("CS")}
            {th("BB")}
            {th("SO")}
            {th("BA")}
            {th("OBP")}
            {th("SLG")}
            {th("OPS")}
            {th("TB")}
            {th("GDP")}
            {th("HBP")}
            {th("SH")}
            {th("SF")}
            {th("IBB")}
          </tr>
        </thead>
        <tbody>
          {Object.entries(stats).map(([year, dataObjects]: [string, any]) =>
            Object.entries(dataObjects).map(([team, data]: [string, any]) => (
              <tr key={year} style={{ backgroundColor: colors.lightGray }}>
                {td(year)}
                {td(team, "TM")}
                {td(data.league, "LG")}
                {tdForBatting(data["games-played"], "G")}
                {tdForBatting(data["plate-appearances"], "PA")}
                {tdForBatting(data["at-bats"], "AB")}
                {tdForBatting(data.runs, "R")}
                {tdForBatting(data.hits, "H")}
                {tdForBatting(data.doubles, "2B")}
                {tdForBatting(data.triples, "3B")}
                {tdForBatting(data["home-runs"], "HR")}
                {tdForBatting(data.rbis, "RBI")}
                {tdForBatting(data["stolen-bases"], "SB")}
                {tdForBatting(data["caught-stealing"], "CS")}
                {tdForBatting(data["bases-on-balls"], "BB")}
                {tdForBatting(data.strikeouts, "SO")}
                {tdForBatting(data["batting-avg"], "BA")}
                {tdForBatting(data["on-base-percentage"], "OBP")}
                {tdForBatting(data["slugging-percentage"], "SLG")}
                {tdForBatting(data["on-base-plus-slugging"], "OPS")}
                {tdForBatting(data["total_bases"], "TB")}
                {tdForBatting(data["double-plays-grounded-into"], "GDP")}
                {tdForBatting(data["hit-by-pitch"], "HBP")}
                {tdForBatting(data["sacrifice-hits"], "SH")}
                {tdForBatting(data["sacrifice-flys"], "SF")}
                {tdForBatting(data["IBB"], "IBB")}
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  };

  const renderNcaaDetails = () => {
    const player = props.player;
    const isPlayerPitcher = isPitcher();

    const stats = isPlayerPitcher
      ? player.pitching_stats
      : player.playerDetails.sr_batting_stats;

    return isPlayerPitcher
      ? renderPitchingStats(stats)
      : renderBattingStats(stats);
  };

  return (
    <>
      <OverlayTrigger
        trigger={["hover", "focus"]}
        placement={"right"}
        overlay={
          <Popover id={`popover-positioned-right`}>
            {props.player && props.player.playerDetails ? (
              <>
                <Popover.Content style={{ margin: 0, padding: 0 }}>
                  {renderNcaaDetails()}
                </Popover.Content>
              </>
            ) : (
              <>
                <Popover.Title
                  as="h3"
                  style={{
                    backgroundColor: "#467DB3",
                    color: "#fff",
                    textAlign: "center",
                  }}
                >
                  Player Details Not Found
                </Popover.Title>
                <Popover.Content
                  style={{ margin: "10px", textAlign: "center" }}
                >
                  No additional information available for this player.
                </Popover.Content>
              </>
            )}
          </Popover>
        }
      >
        <Link
          to={`/ncaa_player/${props.player?.external_id}`}
          className="row-p"
          style={{
            color: colors.accent,
            textDecoration: "underline",
            textDecorationColor: colors.accent,
            textDecorationStyle: "solid",
            whiteSpace: "nowrap",
          }}
        >
          {PlayerService.getName(props.player)}
          <span style={{ fontWeight: 700 }}>
            {props.skipPosition || false
              ? ""
              : ` (${props.player?.primary_position}${
                  props.player?.alt_position
                    ? `, ${props.player?.alt_position}`
                    : ""
                })`}
          </span>
        </Link>
      </OverlayTrigger>
    </>
  );
}

export default PopOver;
