import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "../App.css";
import { QueryHelper } from "services/QueryHelper";
import PlayerAvatar from "shared/player-avatar";
import { PlayerService } from "services/PlayerService";
import colors from "constants/colors";
import CTALink from "shared/cta-link";
import PositionDropdown from "dropdowns/position-dropdown";
import UnderlineButton from "shared/underline-button";
import { AiOutlineLeftCircle } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useMe } from "../hooks/users";
// import { User } from "models/User";
import { CollegePlayerIcon } from "components/smart-icons";

const COLLEGE_POS_MAP = {
  RHP: "Pitcher",
  LHP: "Pitcher",
  "1B": "Infield",
  "2B": "Infield",
  SS: "Infield",
  "3B": "Infield",
  C: "Catcher",
  OF: "Outfield",
};

function Header(props: {
  setSidebarOpen: any;
  setSidebarDocked: any;
  sidebarOpen: boolean;
  hidePlayerSearch?: boolean;
}) {
  const history = useHistory();
  const me = useMe();
  const [search, setSearch] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  let location = useLocation();
  const filterBy = () => true;

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    if (location.pathname.includes("player")) {
      if (window.innerWidth < 1350) {
        props.setSidebarDocked(false);
        props.setSidebarOpen(false);
      }
    }
  };

  const handleSearch = async (query) => {
    setIsLoading(true);
    setSearch(query);

    let url = QueryHelper.urlForNameAutocomplete(query, "name", false);
    let res = await QueryHelper.fetchDataForURLwithCount(url);

    let url2 = QueryHelper.urlForNameAutocomplete(query, "name", true);
    let res2 = await QueryHelper.fetchDataForURLwithCount(url2);

    let urlJuco = QueryHelper.urlForNameAutocomplete(
      query,
      "name",
      false,
      true
    );
    let resJuco = await QueryHelper.fetchDataForURLwithCount(urlJuco);

    if ((res && res.data) || (res2 && res2.data) || (resJuco && resJuco.data)) {
      let resItems = [];

      if (res && res.data) {
        resItems = [...resItems, ...res.data];
      }

      if (res2 && res2.data) {
        resItems = [...resItems, ...res2.data];
      }

      if (resJuco && resJuco.data) {
        resItems = [
          ...resItems,
          ...resJuco.data.map((item) => ({
            ...item,
            junior_college: true,
          })),
        ];
      }

      resItems = resItems.filter((item) =>
        PlayerService.getName(item).toLowerCase().includes(query.toLowerCase())
      );

      if (selectedPositions.length > 0) {
        resItems = resItems.filter((item) => {
          if (item.college) {
            return selectedPositions
              .map((p) => COLLEGE_POS_MAP[p])
              .includes(item.position);
          } else if (item.junior_college) {
            return item.position?.some((p) => selectedPositions.includes(p));
          } else {
            return selectedPositions.includes(item.primary_position);
          }
        });
      }

      resItems.sort(function (a, b) {
        if (a.score && b.score) {
          if (a.score > b.score) return -1;
          if (a.score < b.score) return 1;
        }
        if (a.name && b.name) {
          if (a.name > b.name) return -1;
          if (a.name < b.name) return 1;
        }
        return 0;
      });

      setOptions(
        resItems
          .map((item) => ({
            ...item,
            id: item.external_id,
            name: PlayerService.getName(item),
          }))
          .filter((item) => item.name && item.name !== undefined)
      );
      setIsLoading(false);
    }
  };

  const getOptionPosition = (option: any) => {
    if (option.college) {
      return option.position ? ` (${option.position})` : "";
    } else if (option.junior_college) {
      return option.position ? ` (${option.position.join(" / ")})` : "";
    }
    return option.primary_position ? ` (${option.primary_position})` : "";
  };

  return (
    <nav
      className="navbar fixed-top navbar-expand-xl navbar-dark"
      style={{ backgroundColor: "#0E1E40", padding: 0, margin: 0 }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 12,
            paddingTop: 12,
            justifyContent: "space-between",
          }}
        >
          <Link
            className="navbar-brand"
            style={{ textDecoration: "none", fontWeight: 700, marginRight: 12 }}
            to="/"
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3 style={{ fontWeight: 800 }}>Scout</h3>
              <h3 style={{ fontWeight: 300 }}>SENSE</h3>
            </div>
          </Link>

          <div
            style={{ display: "flex", flexDirection: "row", marginRight: 15 }}
          >
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div style={{ flex: 1 }} />
              <ul className="navbar-nav ">
                <li
                  className={
                    location.pathname === "/prospects"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                    to="/prospects"
                  >
                    MY PROSPECTS
                  </Link>
                </li>
                <li
                  className={
                    location.pathname.includes("scholarships")
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                    to="/scholarships"
                  >
                    SCHOLARSHIPS
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/calendar"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                    to="/calendar"
                  >
                    CALENDAR
                  </Link>
                </li>
                <li
                  className={
                    location.pathname === "/reports"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                    to="/reports"
                  >
                    REPORTS
                  </Link>
                </li>
                <li
                  className={
                    location.pathname.includes("/bigboard")
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                    to={`/bigboard/${me.id}/${me.id}/${
                      me.lastBigBoardYear || "2021"
                    }`}
                  >
                    BIG BOARD
                  </Link>
                </li>

                <li
                  className={
                    location.pathname === "/search-results"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                    to="/search-results?display=full&tab=junior-college"
                  >
                    ADVANCED SEARCH
                  </Link>
                </li>

                <li
                  className={
                    location.pathname === "/monitor"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                    to="/monitor"
                  >
                    MONITOR
                  </Link>
                </li>

                <li
                  className={
                    location.pathname === "/notifications"
                      ? "nav-item active"
                      : "nav-item "
                  }
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontWeight: 700,
                      fontSize: 14,
                    }}
                    to="/notifications"
                  >
                    NOTIFICATIONS
                  </Link>
                </li>

                <li
                  className={
                    location.pathname === "/settings"
                      ? "nav-item active"
                      : "nav-item "
                  }
                  style={{ marginLeft: 15, marginRight: 15, marginTop: -5 }}
                >
                  <Link
                    className="nav-link"
                    style={{
                      textDecoration: "none",
                      fontSize: 24,
                      color: "white",
                    }}
                    to="/settings"
                  >
                    <FontAwesomeIcon icon={faUserCircle as any} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {location.pathname.includes("scholarship") ||
        location.pathname.includes("monitor") ||
        me.isEmpty ||
        props.hidePlayerSearch ? (
          <div
            style={{
              backgroundColor: colors.accent,
              width: "100vw",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        ) : (
          <div
            style={{
              backgroundColor: colors.accent,
              width: "100vw",

              marginTop: 10,
              paddingTop: 12,
              paddingBottom: 12,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "100vw",
                paddingLeft: "10rem",
                paddingRight: "10rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "white",
                  fontSize: 20,
                  marginRight: 20,
                  fontWeight: 800,
                }}
              >
                FIND PLAYER
              </p>
              <AsyncTypeahead
                //@ts-ignore
                style={{ flex: 1, marginRight: 10 }}
                filterBy={filterBy}
                isLoading={isLoading}
                labelKey="name"
                inputProps={{
                  name: "search",
                }}
                minLength={2}
                onSearch={handleSearch}
                selected={[]}
                options={options}
                onChange={(item) => {
                  history.push(
                    `/${
                      item[0].college
                        ? "collegeplayer"
                        : item[0].junior_college
                        ? "juniorcollege"
                        : "player"
                    }/${item[0].external_id}`
                  );
                }}
                placeholder="Name..."
                renderMenuItemChildren={(option, props) => {
                  return (
                    <div
                      {...props}
                      style={{
                        marginLeft: -8,

                        display: "flex",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <PlayerAvatar
                          player={option}
                          blueBackground={true}
                          containerStyles={{ marginRight: 6 }}
                        />
                        {option.name}
                        {getOptionPosition(option)}
                        {option.college ? (
                          <CollegePlayerIcon
                            player={option}
                            style={{ marginLeft: 4 }}
                          />
                        ) : option.junior_college ? (
                          <CollegePlayerIcon
                            junior_college={true}
                            style={{ marginLeft: 4 }}
                          />
                        ) : (
                          <div />
                        )}
                      </span>
                    </div>
                  );
                }}
              />

              <PositionDropdown
                selectPosition={(pos) => {
                  setSelectedPositions(pos);
                }}
              />

              <CTALink
                text="SEARCH"
                linkTo={`/search-results?tab=advanced-search&name=${search}&positions=${selectedPositions.join(
                  ","
                )}`}
                styles={{
                  paddingLeft: 20,
                  paddingRight: 20,
                  marginLeft: 20,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
              />
            </div>
            {props.sidebarOpen ? (
              <div />
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 5,
                  cursor: "pointer",
                  paddingRight: "1rem",
                }}
                onClick={() => {
                  props.setSidebarDocked(true);
                  props.setSidebarOpen(true);
                }}
              >
                <UnderlineButton
                  color={colors.white}
                  text={"open"}
                  onClick={() => {
                    props.setSidebarDocked(true);
                    props.setSidebarOpen(true);
                  }}
                />
                <AiOutlineLeftCircle
                  style={{ color: "white", marginTop: 10, fontSize: 20 }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}

export default Header;
