import React, { useEffect } from "react";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const ListYear = ({
  filters,
  updateFilters,
  handleTabSelection,
  selectedTabItems,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const years = ["Career", 2024, 2023, 2022, 2021, 2020, 2019];

  const handleYearSelect = (year: string | number) => {
    updateFilters((draft) => {
      if (year === "Career") {
        if (filters.selectedYears.includes("Career")) {
          draft.selectedYears = [];
        } else {
          draft.selectedYears = ["Career"];
        }
      } else {
        const newSelection = draft.selectedYears.filter((y) => y !== "Career");
        if (draft.selectedYears.includes(year)) {
          draft.selectedYears = newSelection.filter((y) => y !== year);
        } else {
          draft.selectedYears = [...newSelection, year];
        }
      }
    });
  };

  useEffect(() => {
    if (filters.selectedYears.length >= 1) {
      if (selectedTabItems.year >= 1) return;
      handleTabSelection("year", false);
    } else {
      handleTabSelection("year", true);
    }
  }, [filters.selectedYears]);

  const checkboxStyle = (isChecked: boolean) => ({
    appearance: "none" as const,
    WebkitAppearance: "none" as const,
    width: "16px",
    height: "16px",
    border: isChecked ? "2px solid #4285f4" : "2px solid white",
    borderRadius: "3px",
    backgroundColor: isChecked ? "#4285f4" : "transparent",
    position: "relative" as const,
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    "&::after": isChecked
      ? {
          content: '""',
          width: "5px",
          height: "9px",
          border: "solid white",
          borderWidth: "0 2px 2px 0",
          transform: "rotate(45deg)",
          position: "absolute",
          left: "4px",
          top: "1px",
        }
      : {},
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          color: "white",
          fontSize: 24,
          alignItems: "center",
          gap: 10,
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>Year</p>
        <div
          style={{
            transform: `rotate(${isOpen ? 0 : -90}deg)`,
            transition: "transform 0.1s ease-in-out",
          }}
        >
          <IoIosArrowDown />
        </div>
      </div>

      {isOpen && (
        <div style={{ marginTop: 10, paddingLeft: 20 }}>
          {years.map((year) => {
            const isChecked = filters.selectedYears.includes(year);

            return (
              <div
                key={year}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 8,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => handleYearSelect(year)}
              >
                <div style={checkboxStyle(isChecked)}>
                  {isChecked && (
                    <div
                      style={{
                        width: "5px",
                        height: "9px",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        transform: "rotate(45deg)",
                        marginTop: "-2px",
                      }}
                    />
                  )}
                </div>
                <span style={{ fontSize: 16 }}>{year}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ListYear;
