import React from "react";
import { Button, Spinner } from "react-bootstrap";
import ScoutTitle from "shared/scout-title";
import colors from "constants/colors";
import ResultTableJuniorCollege from "./ResultItem";

interface JuniorCollegeResultsProps {
  loading: boolean;
  results: any[];
  total: number;
  sortOrder: "asc" | "desc" | "";
  sortColumn: string;
  handleSort: (key: string) => void;
  handleLoadMore: () => void;
}

const JuniorCollegeResults = ({
  loading,
  results,
  total,
  sortOrder,
  sortColumn,
  handleSort,
  handleLoadMore,
}: JuniorCollegeResultsProps) => {
  return (
    <div
      style={{
        width: "70%",
        maxHeight: "92vh",
        paddingBottom: "20px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          // height: "90%",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "85vh",
            marginLeft: "1rem",
            padding: "10px",
            flex: 1,
            overflowX: "scroll",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              transform: "translateY(-30px)",
            }}
          >
            <ScoutTitle
              text={`RESULTS ${results?.length}`}
              withHelp={true}
              light={false}
            />
          </div>

          {results?.length > 0 ? (
            <ResultTableJuniorCollege
              data={results}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSort={handleSort}
            />
          ) : (
            <>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "200px",
                  }}
                >
                  <Spinner
                    animation="border"
                    variant="light"
                    style={{
                      width: "3rem",
                      height: "3rem",
                    }}
                  />
                </div>
              ) : (
                <p
                  style={{
                    fontStyle: "italic",
                    opacity: 0.8,
                    marginTop: 100,
                    textAlign: "center",
                    color: "#003366",
                  }}
                >
                  No results.
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <div>
        {/* Load More Button */}

        {results.length > 0 && (
          <div
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                color: colors.accent,
                backgroundColor: "transparent",
                padding: 6,
                borderWidth: 0,
                boxShadow: "none",
                whiteSpace: "nowrap",
              }}
              onClick={handleLoadMore}
            >
              <p
                style={{
                  color: "#003366",
                  textAlign: "center",
                  opacity: 0.6,
                  textDecorationColor: colors.accent,
                  textDecoration: "underline",
                  textDecorationStyle: "solid",
                  fontSize: "18px",
                }}
              >
                Load More
              </p>
            </Button>
          </div>
        )}
        {results.length > 0 && (
          <p
            style={{
              textAlign: "center",
              marginTop: 5,
              color: "#003366",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Total Results: {total}
          </p>
        )}
      </div>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "200px",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
          }}
        >
          <Spinner
            animation="border"
            // variant="light"
            style={{
              width: "3rem",
              height: "3rem",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default JuniorCollegeResults;
