import React, { FC, useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { fetchYearsFromES } from "../../../elasticsearchQueries";

interface YearProps {
  handleYearSelect: (year: string[]) => void;
  year: string[];
}

const Year: FC<YearProps> = ({ handleYearSelect, year }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [years, setYears] = useState<string[]>([]);
  const [selectedYears, setSelectedYears] = useState<string[]>([]);

  useEffect(() => {
    const getYears = async () => {
      const fetchedYears = await fetchYearsFromES();
      setYears(fetchedYears);
    };
    getYears();
  }, []);

  useEffect(() => {
    setSelectedYears(year);
  }, [year]);

  const onYearSelect = (year: string) => {
    let newSelection: string[];

    if (selectedYears.includes(year)) {
      newSelection = selectedYears.filter((y) => y !== year);
    } else {
      newSelection = [...selectedYears, year];
    }

    setSelectedYears(newSelection);
    handleYearSelect(newSelection);
  };

  const checkboxStyle = (isChecked: boolean) => ({
    appearance: "none" as const,
    WebkitAppearance: "none" as const,
    width: "16px",
    height: "16px",
    border: isChecked ? "2px solid #4285f4" : "2px solid white",
    borderRadius: "3px",
    backgroundColor: isChecked ? "#4285f4" : "transparent",
    position: "relative" as const,
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <div
      style={{
        marginTop: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          color: "white",
          fontSize: 24,
          alignItems: "center",
          gap: 10,
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>Year</p>
        <div
          style={{
            transform: `rotate(${isOpen ? 0 : -90}deg)`,
            transition: "transform 0.1s ease-in-out",
          }}
        >
          <IoIosArrowDown />
        </div>
      </div>

      {isOpen && (
        <div style={{ marginTop: 10, paddingLeft: 20 }}>
          {years.map((year) => {
            const isChecked = selectedYears.includes(year);

            return (
              <div
                key={year}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 8,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => onYearSelect(year)}
              >
                <div style={checkboxStyle(isChecked)}>
                  {isChecked && (
                    <div
                      style={{
                        width: "5px",
                        height: "9px",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        transform: "rotate(45deg)",
                        marginTop: "-2px",
                      }}
                    />
                  )}
                </div>
                <span style={{ fontSize: 16 }}>{year}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Year;
