import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";

function Stat({
  valmin,
  valmax,
  setValues,
  min,
  max,
  step,
  units,
  title,
  type,
  setType,
}) {
  const COLORS = [
    "rgba(256, 256, 256, 0.4)",
    "#fff",
    "rgba(256, 256, 256, 0.4)",
  ];

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    let newMin = min;
    let newMax = max;

    switch (type) {
      case "exact":
        newMin = value;
        newMax = value;
        break;
      case "min":
        newMin = value;
        break;
      case "max":
        newMax = value;
        break;
      default:
        return;
    }
    setValues([newMin, newMax]);
  };

  const handleInputBlur = () => {
    const numValue = Number(inputValue);
    if (numValue > max || numValue < min) {
      setInputValue("");
    }
  };

  const hanleTypeChange = (typeValue: string) => {
    if (inputValue === "") return;
    if (typeValue === "min") {
      setValues([inputValue, max]);
    } else if (typeValue === "max") {
      setValues([min, inputValue]);
    } else if (typeValue === "exact") {
      setValues([inputValue, inputValue]);
    }
    setType(typeValue);
  };

  useEffect(() => {
    if (inputValue === "") {
      setType("");
    }
  }, [inputValue]);

  useEffect(() => {
    if (!type) {
      setInputValue("");
    }
  }, [type]);

  const valWithUnits = (val) => {
    if (units === "lbs") {
      return `${val} lbs`;
    } else if (units === "in") {
      return `${Math.floor(val / 12)}'${val % 12}`;
    } else if (units === "collegeClass") {
      switch (val) {
        case 1:
          return "Freshman";
        case 2:
          return "Sophomore";
        case 3:
          return "Junior   ";
        case 4:
          return "Senior   ";
      }
    } else {
      console.log("no match");
    }
    return val;
  };

  let calcMin = Math.max(min, valmin);
  let calcMax = Math.min(max, valmax);

  let values = [calcMin, calcMax];

  const checkboxStyle = (isChecked: boolean) => ({
    appearance: "none" as const,
    WebkitAppearance: "none" as const,
    width: "16px",
    height: "16px",
    border: isChecked ? "2px solid #4285f4" : "2px solid white",
    borderRadius: "3px",
    backgroundColor: isChecked ? "#4285f4" : "transparent",
    position: "relative" as const,
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: 10,
          width: "fit-content",
          paddingLeft: "40px",
          marginBottom: "10px",
        }}
      >
        <p style={{ marginRight: "10px", color: "white", width: 40 }}>
          {title}
        </p>
        <input
          type="number"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          style={{ width: "50px", height: "20px", marginRight: "10px" }}
          min={min}
          max={max}
          step={step}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <p style={{ color: "white" }}>Min</p>
          <div
            style={checkboxStyle(type === "min")}
            onClick={() => hanleTypeChange("min")}
          >
            {type === "min" && (
              <div
                style={{
                  width: "5px",
                  height: "9px",
                  borderRight: "2px solid white",
                  borderBottom: "2px solid white",
                  transform: "rotate(45deg)",
                  marginTop: "-2px",
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <p style={{ color: "white" }}>Max</p>
          <div
            style={checkboxStyle(type === "max")}
            onClick={() => hanleTypeChange("max")}
          >
            {type === "max" && (
              <div
                style={{
                  width: "5px",
                  height: "9px",
                  borderRight: "2px solid white",
                  borderBottom: "2px solid white",
                  transform: "rotate(45deg)",
                  marginTop: "-2px",
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          <p style={{ color: "white" }}>Exact</p>
          <div
            style={checkboxStyle(type === "exact")}
            onClick={() => hanleTypeChange("exact")}
          >
            {type === "exact" && (
              <div
                style={{
                  width: "5px",
                  height: "9px",
                  borderRight: "2px solid white",
                  borderBottom: "2px solid white",
                  transform: "rotate(45deg)",
                  marginTop: "-2px",
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="range-component">
        <div className="data-box">
          <p className="data-text">{valWithUnits(valmin)}</p>
        </div>

        <Range
          values={values}
          step={step}
          min={min}
          max={max}
          onChange={(values) => {
            setValues([values[0], values[1]]);
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: "36px",
                display: "flex",
                width: "100%",
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: [valmin, valmax],
                    colors: COLORS,
                    min: min,
                    max: max,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => {
            return (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "20px",
                  width: "20px",
                  backgroundColor: "white",
                  borderRadius: "300px",
                }}
              />
            );
          }}
        />
        <div className="data-box">
          <p className="data-text">{valWithUnits(valmax)}</p>
        </div>
      </div>
    </div>
  );
}

export default Stat;
