import React, { useState, FC, useEffect } from "react";

const DIVISIONS = [1, 2, 3];

interface DivisionProps {
  handleDivisionSelect: (division: number[]) => void;
  division: number[];
}

const Division: FC<DivisionProps> = ({ handleDivisionSelect, division }) => {
  return (
    <div>
      <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>Division</h4>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {DIVISIONS.map((item, index) => (
          <button
            key={index}
            onClick={() => {
              if (division.includes(item)) {
                handleDivisionSelect(
                  division.filter((division) => division !== item)
                );
              } else {
                handleDivisionSelect([...division, item]);
              }
            }}
            style={{
              backgroundColor: division.includes(item)
                ? "white"
                : "transparent",
              color: division.includes(item) ? "#003366" : "#fff",
              padding: 4,
              border: division.includes(item)
                ? "2px solid transparent"
                : "2px solid white",
              width: "30%",
              borderRadius: 8,
              marginBottom: 8,
            }}
          >
            {item}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Division;
