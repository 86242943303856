import React, { useEffect, useMemo, useState } from "react";
import Division from "./components/Division";
import Region from "./components/Region";
import Year from "./components/Year";
import School from "./components/School";
import HomeTown from "./components/HomeTown";
import Height from "./components/Height";
import Weight from "./components/Weight";
import Position from "./components/Position";
import Bat from "./components/Bat";
import Throw from "./components/Throw";
import Batting from "./components/Batting";
import Pitching from "./components/Pitching";

import {
  battingFields,
  initialFilters,
  pitchingFields,
} from "./components/defaultSportFilters";
import JuniorCollegeResults from "./components/Results";
import { fetchJuniorCollegeData } from "pages/search-page/elasticsearchQueries";

const TABS = ["NJCAA", "CCCAA"];

interface JuniorCollegeProps {
  filters: any;
  setFilters: any;
  showSearchResults: boolean;
  setShowSearchResults: (showSearchResults: boolean) => void;
}

const JuniorCollege = ({
  showSearchResults,
  setShowSearchResults,
  filters,
  setFilters,
}: JuniorCollegeProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(TABS[0]);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [from, setFrom] = useState(0);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");
  const [sortColumn, setSortColumn] = useState("");
  const [isReload, setIsReload] = useState(false);
  const loadMoreSize = 20;

  const fetchResults = async (fromIndex: number) => {
    try {
      setLoading(true);

      const res = await fetchJuniorCollegeData(
        filters,
        loadMoreSize,
        fromIndex,
        sortOrder,
        sortColumn
      );

      setResults((prev) => [...prev, ...res.data]);

      setTotal(res.total);
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = async () => {
    try {
      if (total === results.length) {
        return;
      }
      const newFrom = from + loadMoreSize;
      console.log("newFrom", newFrom);
      setFrom(newFrom);
      const newUrl = new URL(window.location.href);

      window.history.pushState({}, "", newUrl);

      await fetchResults(newFrom);
    } catch (error) {
      console.error("Error in load more:", error);
    }
  };

  const handleSort = (key: string) => {
    if (key === "name") return;

    if (sortColumn === key) {
      setSortOrder((prevOrder) =>
        prevOrder === "asc" ? "desc" : prevOrder === "desc" ? "" : "asc"
      );
    } else {
      setSortColumn(key);
      setSortOrder("asc");
    }
  };

  const handleDivisionSelect = (division: number[]) => {
    setFilters({
      ...filters,
      division: division,
    });
  };

  const handleRegionSelect = (region: string[]) => {
    setFilters({
      ...filters,
      region: region,
    });
  };

  const handleYearSelect = (year: string[]) => {
    setFilters((draft) => {
      draft.year = year;
    });
  };

  const handleSchoolSelect = (schools: string[]) => {
    setFilters((draft) => {
      draft.school = schools;
    });
  };

  const handleHomeTownSelect = (homeTown: string[]) => {
    setFilters((draft) => {
      draft.homeTown = homeTown;
    });
  };

  const handleHeightChange = (height: string[]) => {
    const newMin = parseFloat(height[0]);
    const newMax = parseFloat(height[1]);
    const isDifferent =
      newMin !== initialFilters.height.min ||
      newMax !== initialFilters.height.max;

    setFilters({
      ...filters,
      height: {
        min: newMin,
        max: newMax,
        active: isDifferent,
      },
    });
  };

  const handleWeightChange = (weight: string[]) => {
    const newMin = parseFloat(weight[0]);
    const newMax = parseFloat(weight[1]);
    const isDifferent =
      newMin !== initialFilters.weight.min ||
      newMax !== initialFilters.weight.max;

    setFilters({
      ...filters,
      weight: {
        min: newMin,
        max: newMax,
        active: isDifferent,
      },
    });
  };
  const handlePositionSelect = (position: string[]) => {
    setFilters((draft) => {
      draft.position = position;
    });
  };

  const handleBatSelection = (bat: string) => {
    setFilters({
      ...filters,
      bat: bat,
    });
  };

  const handleThrowSelection = (throwType: string) => {
    setFilters({
      ...filters,
      throw: throwType,
    });
  };

  const handleChangeTab = (tab: string) => {
    setResults([]);
    setTotal(0);
    setFrom(0);
    setFilters({
      ...initialFilters,
      league: tab,
    });
    setSelectedTab(tab);
    const currentUrl = new URL(window.location.href);

    const newUrl = new URL(window.location.origin + window.location.pathname);
    newUrl.searchParams.set("tab", "junior-college");
    newUrl.searchParams.set("league", tab);

    if (currentUrl.searchParams.has("showResults")) {
      newUrl.searchParams.set("showResults", "true");
    }

    window.history.pushState({}, "", newUrl);
  };

  const resetFilters = () => {
    setResults([]);
    setTotal(0);
    setFrom(0);
    setFilters(initialFilters);

    let newUrl = "/search-results?tab=junior-college";
    const currentUrl = new URL(window.location.href);

    if (currentUrl.searchParams.has("showResults")) {
      newUrl += "&showResults=true";
    }

    if (currentUrl.searchParams.has("league")) {
      newUrl += `&league=${currentUrl.searchParams.get("league")}`;
    }

    window.history.pushState({}, "", newUrl);
  };

  const totalFilters = useMemo(() => {
    let total = 0;

    for (const key in filters) {
      if (key === "league") continue;
      const currentFilter = filters[key];
      const initialFilter = initialFilters[key];
      if (
        Object.prototype.hasOwnProperty.call(currentFilter, "active") &&
        currentFilter.active
      ) {
        total++;
      } else if (
        JSON.stringify(currentFilter) !== JSON.stringify(initialFilter)
      ) {
        total++;
      }
    }

    return total;
  }, [filters]);

  const handleApplyFilters = () => {
    setResults([]);
    setTotal(0);
    setFrom(0);
    setLoading(true);
    setSortOrder("");
    setSortColumn("");
    fetchResults(0);
    const newUrl = new URL(window.location.href);

    Object.keys(initialFilters).forEach((key) => {
      if (key === "school") {
        newUrl.searchParams.delete("team");
      } else {
        newUrl.searchParams.delete(key);
      }
    });

    Object.entries(filters).forEach(([key, value]) => {
      if (key === "school") {
        if (Array.isArray(value) && value.length > 0) {
          newUrl.searchParams.set("team", value.join(","));
        }
        return;
      }
      if (Array.isArray(value) && value.length > 0) {
        newUrl.searchParams.set(key, value.join(","));
      } else if (typeof value === "boolean" && value) {
        newUrl.searchParams.set(key, "true");
      } else if (value && typeof value === "string" && value.length > 0) {
        newUrl.searchParams.set(key, value);
      } else if (typeof value === "number") {
        newUrl.searchParams.set(key, (value as Number).toString());
      } else if (typeof value === "object" && value !== null) {
        const { min, max, active } = value as {
          min: number;
          max: number;
          active: boolean;
        };
        const initialRange = initialFilters[key];
        if (
          initialRange &&
          active &&
          (min !== initialRange.min || max !== initialRange.max)
        ) {
          newUrl.searchParams.set(`${key}_min`, min.toString());
          newUrl.searchParams.set(`${key}_max`, max.toString());
        }
      }
    });

    newUrl.searchParams.set("showResults", "true");

    window.history.pushState({}, "", newUrl);
    setShowSearchResults(true);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filtersFromUrl = {};

    // Parse URL parameters into filters object
    params.forEach((value, key) => {
      if (key === "team") {
        filtersFromUrl["school"] = value.split(",");
      } else if (key === "division") {
        filtersFromUrl[key] = value.split(",").map(Number);
      } else if (key === "region") {
        filtersFromUrl[key] = value.split(",");
      } else if (key === "year") {
        filtersFromUrl[key] = value.split(",");
      } else if (key === "league") {
        filtersFromUrl[key] = value;
      } else if (key === "hometown") {
        filtersFromUrl[key] = value.split(",");
      } else if (key === "position") {
        filtersFromUrl[key] = value.split(",");
      } else if (key.includes("_min") || key.includes("_max")) {
        const baseKey = key.replace("_min", "").replace("_max", "");
        if (!filtersFromUrl[baseKey]) {
          filtersFromUrl[baseKey] = {
            min: initialFilters[baseKey].min,
            max: initialFilters[baseKey].max,
            active: true,
          };
        }
        if (key.includes("_min")) {
          filtersFromUrl[baseKey].min = parseFloat(value);
        } else {
          filtersFromUrl[baseKey].max = parseFloat(value);
        }
      } else if (["bat", "throw"].includes(key)) {
        filtersFromUrl[key] = value;
      }
    });

    const newUrl = new URL(window.location.href);

    if (Object.keys(filtersFromUrl).length > 0) {
      setFilters((draft) => {
        return {
          ...draft,
          ...filtersFromUrl,
        };
      });
    }

    if (newUrl.searchParams.get("league") === "NJCAA") {
      setSelectedTab(TABS[0]);
    } else if (newUrl.searchParams.get("league") === "CCCAA") {
      setSelectedTab(TABS[1]);
    }
    setIsReload(true);
  }, []);

  useEffect(() => {
    if (sortColumn && sortOrder) {
      setResults([]);
      setFrom(0);
      fetchResults(0);
    }
  }, [sortColumn, sortOrder]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const allowedParams = ["showResults", "league", "tab"];
    let onlyAllowedParams = true;

    for (const key of params.keys()) {
      if (!allowedParams.includes(key)) {
        onlyAllowedParams = false;
        break;
      }
    }

    if (isReload && !onlyAllowedParams) {
      fetchResults(0);
    }
  }, [isReload]);

  return (
    <>
      <div
        style={{
          height: "92vh",
          paddingBottom: "100px",
          overflowY: "scroll",
          width: showSearchResults ? "30%" : "1000px",
          backgroundColor: "#003366",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          {TABS.map((tab) => (
            <h2
              style={{
                fontSize: 18,
                fontWeight: 300,
                color: "white",
                marginBottom: 10,
                cursor: "pointer",
                borderBottom:
                  selectedTab === tab
                    ? "2px solid white"
                    : "2px solid transparent",
              }}
              key={tab}
              onClick={() => handleChangeTab(tab)}
            >
              {tab}
            </h2>
          ))}
        </div>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            marginTop: 20,
            fontSize: 24,
          }}
        >
          GENERAL INFOMATION
        </p>
        <div>
          {selectedTab === TABS[0] && (
            <>
              <Division
                handleDivisionSelect={handleDivisionSelect}
                division={filters.division}
              />
              <Region
                handleRegionSelect={handleRegionSelect}
                region={filters.region}
              />
            </>
          )}
          <Year handleYearSelect={handleYearSelect} year={filters.year} />
          <School
            handleSchoolSelect={handleSchoolSelect}
            school={filters.school}
          />
          <HomeTown
            handleHomeTownSelect={handleHomeTownSelect}
            homeTown={filters.homeTown}
          />
          {selectedTab === TABS[0] && (
            <>
              <Height
                handleHeightChange={handleHeightChange}
                valmax={filters.height.max}
                valmin={filters.height.min}
              />
              <Weight
                handleWeightChange={handleWeightChange}
                valmax={filters.weight.max}
                valmin={filters.weight.min}
              />
            </>
          )}
        </div>
        <p
          style={{
            color: "white",
            fontWeight: "bold",
            marginTop: 20,
            fontSize: 24,
          }}
        >
          PLAYER INFORMATION
        </p>
        <div>
          <Position
            handlePositionSelect={handlePositionSelect}
            position={filters.position}
          />
          <Bat handleBatSelection={handleBatSelection} batData={filters.bat} />
          <Throw
            handleThrowSelection={handleThrowSelection}
            throwData={filters.throw}
          />
        </div>
        <Batting filters={filters} setFilters={setFilters} />
        <Pitching filters={filters} setFilters={setFilters} />
        <div style={{ marginTop: "20px" }}>
          <button
            style={{
              backgroundColor: "white",
              color: "#003366",
              padding: 4,
              border: "2px solid white",
              whiteSpace: "nowrap",
              borderRadius: 8,
              cursor: "pointer",
            }}
            onClick={resetFilters}
          >
            Reset Filters
          </button>
          {totalFilters > 0 && (
            <button
              style={{
                backgroundColor: "white",
                color: "#003366",
                padding: 4,
                border: "2px solid white",
                borderRadius: 8,
                cursor: "pointer",
                marginLeft: 20,
                whiteSpace: "nowrap",
              }}
              onClick={handleApplyFilters}
            >
              Apply Filters ({totalFilters})
            </button>
          )}
        </div>
      </div>
      {showSearchResults && (
        <JuniorCollegeResults
          loading={loading}
          results={results}
          total={total}
          sortOrder={sortOrder}
          sortColumn={sortColumn}
          handleSort={handleSort}
          handleLoadMore={handleLoadMore}
        />
      )}
    </>
  );
};

export default JuniorCollege;
