import React, { ReactNode, useEffect, useState } from "react";
import { useImmer } from "use-immer";
import PortalResult from "./PortalResult";
import { Switch as MUISwitch } from "@material-ui/core";
import ConferenceDropwdown from "dropdowns/conference-dropdown";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import InstitutionDropdown from "dropdowns/Institution-dropdown";
import EditSavedSearchModal from "modals/edit-saved-search-modal";
import { Button } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import colors from "constants/colors";
import { auth } from "store/store";
import { useSelector } from "react-redux";
import moment from "moment";
import UnderlineButton from "shared/underline-button";
import { MAXES, MINS } from "constants/search";
import PositionDropdownNcaa from "dropdowns/position-dropdown-ncaa";
import PopupWarning from "./PopupWarning";
import Level from "./PlayerInfomation/Level";
import ListYear from "./PlayerInfomation/ListYear";
import Bats from "./PlayerInfomation/Bats";
import Throws from "./PlayerInfomation/Throws";
import PitchingStats from "./PitchingStats";
import BattingStats from "./BattingStats";
/*****************************************************************************
 * Types
 *****************************************************************************/

type TransferStatus = (typeof TRANSFER_STATUS)[number];
type Division = (typeof DIVISIONS)[number];
type FilterId = keyof InitialFilters;

export type InitialFilters = {
  institution: string;
  graduate: boolean;
  mtr: boolean;
  transferStatus: TransferStatus[];
  conference: string;
  division: Division[];
  // Add player information fields
  level: string;
  year: string;
  bats: string;
  throws: string;
  w?: any; // wins
  l?: any; // losses
  wp?: any; // wild-pitches
  era?: any; // earned-run-average
  ra?: any; //run-average
  gs?: any; // games-started
  gf?: any; // games-finished
  cg?: any; // complete-game
  sho?: any; // shutouts
  sv?: any; // saves
  ip?: any; // innings-pitched
  h?: any; // hits-allowed
  r?: any; // runs-allowed
  er?: any; // earned-runs-allowed
  hr?: any; // home-runs-per-nine
  g?: any; // games-played
  bb?: any; // bases-on-balls
  ibb?: any; // intentional-bases-on-balls
  wlpct?: any; // win-loss-percentage
  so?: any; // strikeouts
  yearstats?: any; // year-stats
  positions?: any;
  selectedYears: (string | number)[];
  batting_g?: any;
  batting_pa?: any;
  batting_ab?: any;
  batting_r?: any;
  batting_h?: any;
  batting_2b?: any;
  batting_3b?: any;
  batting_hr?: any;
  batting_rbi?: any;
  batting_sb?: any;
  batting_cs?: any;
  batting_bb?: any;
  batting_so?: any;
  batting_avg?: any;
  batting_obp?: any;
  batting_slg?: any;
  batting_ops?: any;
  batting_tb?: any;
  batting_gdp?: any;
  batting_hbp?: any;
  batting_sh?: any;
  batting_sf?: any;
  batting_ibb?: any;
};

export const stats = [
  // "YEARSTATS",
  "W",
  "L",
  "ERA",
  "GS",
  "CG",
  "SHO",
  "SV",
  "IP",
  "H",
  "R",
  "ER",
  "HR",
  "BB",
  "WP",
  "RA",
  "IBB",
  "WLPCT",

  // "G",
  // "GF",
];

export const MAXESPITCHING = {
  G: 50,
  W: 15,
  L: 15,
  ERA: 15,
  GS: 20,
  CG: 5,
  SHO: 5,
  SV: 20,
  IP: 100,
  H: 100,
  R: 50,
  ER: 50,
  HR: 10,
  BB: 50,
  WP: 10,
  RA: 100,
  IBB: 100,
  WLPCT: 1,
  SO: 150,
  HBP: 100,
  WHIP: 10,
  BAA: 1,
  "2B": 10,
  "3B": 10,
  SF: 10,
  SH: 10,
  SPG: 1,
  BK: 10,
};

export const MAXESBATTING = {
  G: 200,
  PA: 300,
  AB: 300,
  R: 100,
  H: 150,
  "2B": 50,
  "3B": 10,
  HR: 30,
  RBI: 75,
  SB: 30,
  CS: 10,
  BB: 50,
  SO: 75,
  AVG: 1,
  OBP: 1,
  SLG: 1.5,
  OPS: 2,
  TB: 200,
  GDP: 10,
  HBP: 10,
  SH: 10,
  SF: 10,
  IBB: 10,
};

/*****************************************************************************
 * Constants
 *****************************************************************************/

const TRANSFER_STATUS = ["INC", "WTH", "MAT"];
const DIVISIONS = [1, 2, 3];

const initialFilters: InitialFilters = {
  institution: "",
  graduate: false,
  mtr: false,
  transferStatus: [],
  conference: "",
  division: [],
  // values for player information
  positions: [],
  level: "",
  year: "",
  bats: "",
  throws: "",
  yearstats: {
    min: MINS[stats[0]],
    max: MAXES[stats[0]],
    active: false,
    type: "",
  },

  batting_g: {
    min: 0,
    max: MAXESBATTING["G"],
    active: false,
    type: "",
  },
  batting_pa: {
    min: 0,
    max: MAXESBATTING["PA"],
    active: false,
    type: "",
  },
  batting_ab: {
    min: 0,
    max: MAXESBATTING["AB"],
    active: false,
    type: "",
  },
  batting_r: {
    min: 0,
    max: MAXESBATTING["R"],
    active: false,
    type: "",
  },
  batting_h: {
    min: 0,
    max: MAXESBATTING["H"],
    active: false,
    type: "",
  },
  batting_2b: {
    min: 0,
    max: MAXESBATTING["2B"],
    active: false,
    type: "",
  },
  batting_3b: {
    min: 0,
    max: MAXESBATTING["3B"],
    active: false,
    type: "",
  },
  batting_hr: {
    min: 0,
    max: MAXESBATTING["HR"],
    active: false,
    type: "",
  },
  batting_rbi: {
    min: 0,
    max: MAXESBATTING["RBI"],
    active: false,
    type: "",
  },
  batting_sb: {
    min: 0,
    max: MAXESBATTING["SB"],
    active: false,
    type: "",
  },
  batting_cs: {
    min: 0,
    max: MAXESBATTING["CS"],
    active: false,
    type: "",
  },
  batting_bb: {
    min: 0,
    max: MAXESBATTING["BB"],
    active: false,
    type: "",
  },
  batting_so: {
    min: 0,
    max: MAXESBATTING["SO"],
    active: false,
    type: "",
  },
  batting_avg: {
    min: 0,
    max: MAXESBATTING["AVG"],
    active: false,
    type: "",
  },
  batting_obp: {
    min: 0,
    max: MAXESBATTING["OBP"],
    active: false,
    type: "",
  },
  batting_slg: {
    min: 0,
    max: MAXESBATTING["SLG"],
    active: false,
    type: "",
  },
  batting_ops: {
    min: 0,
    max: MAXESBATTING["OPS"],
    active: false,
    type: "",
  },
  batting_tb: {
    min: 0,
    max: MAXESBATTING["TB"],
    active: false,
    type: "",
  },
  batting_gdp: {
    min: 0,
    max: MAXESBATTING["GDP"],
    active: false,
    type: "",
  },
  batting_hbp: {
    min: 0,
    max: MAXESBATTING["HBP"],
    active: false,
    type: "",
  },
  batting_sh: {
    min: 0,
    max: MAXESBATTING["SH"],
    active: false,
    type: "",
  },
  batting_sf: {
    min: 0,
    max: MAXESBATTING["SF"],
    active: false,
    type: "",
  },
  batting_ibb: {
    min: 0,
    max: MAXESBATTING["IBB"],
    active: false,
    type: "",
  },

  g: {
    min: 0,
    max: MAXESPITCHING["G"],
    active: false,
    type: "",
  },
  w: {
    min: MINS[stats[1]],
    max: MAXESPITCHING["W"],
    active: false,
    type: "",
  },
  l: {
    min: MINS[stats[2]],
    max: MAXESPITCHING["L"],
    active: false,
    type: "",
  },
  era: {
    min: MINS[stats[3]],
    max: MAXESPITCHING["ERA"],
    active: false,
    type: "",
  },
  gs: {
    min: MINS[stats[4]],
    max: MAXESPITCHING["GS"],
    active: false,
    type: "",
  },
  cg: {
    min: MINS[stats[5]],
    max: MAXESPITCHING["CG"],
    active: false,
    type: "",
  },
  sho: {
    min: MINS[stats[6]],
    max: MAXESPITCHING["SHO"],
    active: false,
    type: "",
  },
  sv: {
    min: MINS[stats[7]],
    max: MAXESPITCHING["SV"],
    active: false,
    type: "",
  },
  ip: {
    min: MINS[stats[8]],
    max: MAXESPITCHING["IP"],
    active: false,
    type: "",
  },
  h: {
    min: MINS[stats[9]],
    max: MAXESPITCHING["H"],
    active: false,
    type: "",
  },
  r: {
    min: MINS[stats[10]],
    max: MAXESPITCHING["R"],
    active: false,
    type: "",
  },
  er: {
    min: MINS[stats[11]],
    max: MAXESPITCHING["ER"],
    active: false,
    type: "",
  },
  hr: {
    min: MINS[stats[12]],
    max: MAXESPITCHING["HR"],
    active: false,
    type: "",
  },
  bb: {
    min: MINS[stats[13]],
    max: MAXESPITCHING["BB"],
    active: false,
    type: "",
  },
  wp: {
    min: MINS[stats[14]],
    max: MAXESPITCHING["WP"],
    active: false,
    type: "",
  },
  ra: {
    min: MINS[stats[15]],
    max: MAXESPITCHING["RA"],
    active: false,
    type: "",
  },
  ibb: {
    min: MINS[stats[16]],
    max: MAXESPITCHING["IBB"],
    active: false,
    type: "",
  },
  wlpct: {
    min: MINS[stats[17]],
    max: MAXESPITCHING["WLPCT"],
    active: false,
    type: "",
  },
  so: {
    min: MINS[stats[18]],
    max: MAXESPITCHING["SO"],
    active: false,
    type: "",
  },
  selectedYears: [],
};

/*****************************************************************************
 * AdvancedSearchPortal
 *****************************************************************************/

export default function AdvancedSearchPortal({
  setShowAdvancedOptions,
  setShowFilterIcon,
  showAll,
  handleShowAll,
  isNCAA,
  showAdvancedOptions,
}: {
  setShowAdvancedOptions: (value: boolean) => void;
  setShowFilterIcon: (value: boolean) => void;
  showAll: boolean;
  handleShowAll: () => void;
  isNCAA: boolean;
  showAdvancedOptions: boolean;
}) {
  const [filters, updateFilters] = useImmer(initialFilters);
  const [selectedTabCount, setSelectedTabCount] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPositionsSelected, setIsPositionsSelected] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [selectedTabItems, setSelectedTabItems] = useState<
    Record<FilterId, any>
  >({
    institution: 0,
    graduate: 0,
    mtr: 0,
    transferStatus: 0,
    conference: 0,
    division: 0,
    level: 0,
    year: 0,
    bats: 0,
    throws: 0,
    selectedYears: 0,
    w: 0,
    l: 0,
    wp: 0,
    era: 0,
    ra: 0,
    gs: 0,
    gf: 0,
    cg: 0,
    sho: 0,
    sv: 0,
    ip: 0,
    h: 0,
    r: 0,
    er: 0,
    hr: 0,
    g: 0,
    bb: 0,
    ibb: 0,
    wlpct: 0,
    so: 0,
    yearstats: 0,
    positions: 0,
    batting_g: 0,
    batting_pa: 0,
    batting_ab: 0,
    batting_r: 0,
    batting_h: 0,
    batting_2b: 0,
    batting_3b: 0,
    batting_hr: 0,
    batting_rbi: 0,
    batting_sb: 0,
    batting_cs: 0,
    batting_bb: 0,
    batting_so: 0,
    batting_avg: 0,
    batting_obp: 0,
    batting_slg: 0,
    batting_ops: 0,
    batting_tb: 0,
    batting_gdp: 0,
    batting_hbp: 0,
    batting_sh: 0,
    batting_sf: 0,
    batting_ibb: 0,
  });
  const firestore = useFirestore();
  const [institutions, setInstitutions] = useState<any[]>([]);
  const [editSavedSearch, setEditSavedSearch] = React.useState(null);
  const [showSaved, setShowSaved] = useState(false);
  const [selectedTabs, setSelectedTabs] = useState<Set<FilterId>>(new Set());
  const mySearches = useSelector(
    (state: any) => state.firestore.data.mySearches
  );

  const getInstitutions = async () => {
    try {
      const institutionsSnapshot = await firestore
        .collection("institutions")
        .orderBy("institution", "asc")
        .get();
      const institutions = institutionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return institutions;
    } catch (error) {
      console.error("Error fetching institutions: ", error);
      return [];
    }
  };

  useEffect(() => {
    getInstitutions().then((institutions) => {
      setInstitutions(institutions);
    });
  }, []);

  function resetAllFilters() {
    setFiltersApplied(false);
    updateFilters(() => initialFilters);
    setSelectedTabCount(0);
    setSelectedTabs(new Set());

    const newUrl = new URL(window.location.href);
    Object.keys(initialFilters).forEach((key) => {
      newUrl.searchParams.delete(key);
    });
    window.history.pushState({}, "", newUrl);
  }

  const handleApplyFilters = async () => {
    try {
      setShowFilterIcon(true);
      setShowAdvancedOptions(false);
      setFiltersApplied(true);

      const newUrl = new URL(window.location.href);

      Object.keys(initialFilters).forEach((key) => {
        newUrl.searchParams.delete(key);
      });

      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          newUrl.searchParams.set(key, value.join(","));
        } else if (typeof value === "boolean" && value) {
          newUrl.searchParams.set(key, "true");
        } else if (value && typeof value === "string" && value.length > 0) {
          newUrl.searchParams.set(key, value);
        } else if (typeof value === "number") {
          newUrl.searchParams.set(key, value.toString());
        } else if (typeof value === "object" && value !== null) {
          const { min, max, active } = value;
          const initialRange = initialFilters[key];
          if (
            initialRange &&
            active &&
            (min !== initialRange.min || max !== initialRange.max)
          ) {
            newUrl.searchParams.set(`${key}_min`, min.toString());
            newUrl.searchParams.set(`${key}_max`, max.toString());
          }
        }
      });

      window.history.pushState({}, "", newUrl);
      handleShowAll();
    } catch (error) {
      console.error("Error applying filters:", error);
    }
  };

  useEffect(() => {
    setFiltersApplied(false);
  }, []);

  const canFilterPlayerInfomation = () => {
    const statKeys = [
      "level",
      "year",
      "bats",
      "throws",
      "w",
      "l",
      "wp",
      "era",
      "ra",
      "gs",
      "gf",
      "cg",
      "sho",
      "sv",
      "ip",
      "h",
      "r",
      "er",
      "hr",
      "g",
      "bb",
      "ibb",
      "wlpct",
      "so",
      "yearstats",
      "positions",
      "batting_g",
      "batting_pa",
      "batting_ab",
      "batting_r",
      "batting_h",
      "batting_2b",
      "batting_3b",
      "batting_hr",
      "batting_rbi",
      "batting_sb",
      "batting_cs",
      "batting_bb",
      "batting_so",
      "batting_avg",
      "batting_obp",
      "batting_slg",
      "batting_ops",
      "batting_tb",
      "batting_gdp",
      "batting_hbp",
      "batting_sh",
    ];

    const hasOtherActiveFilters = Object.entries(selectedTabItems).some(
      ([key, value]) => !statKeys.includes(key) && value >= 1
    );

    const allStatKeysInactive = statKeys.every((key) => !selectedTabItems[key]);

    return (
      (statKeys.some((key) => selectedTabItems[key] >= 1) &&
        selectedTabItems.positions > 0) ||
      (allStatKeysInactive && hasOtherActiveFilters)
    );
  };

  const handleTabSelection = (tabId: FilterId, wasSelected: boolean) => {
    setSelectedTabItems((prevSelectedItems) => {
      const updatedSelectedItems = { ...prevSelectedItems };

      updatedSelectedItems[tabId] = wasSelected
        ? Math.max(updatedSelectedItems[tabId] - 1, 0)
        : updatedSelectedItems[tabId] + 1;

      setSelectedTabs((prevSelectedTabs) => {
        const newSelectedTabs = new Set(prevSelectedTabs);
        if (updatedSelectedItems[tabId] > 0) {
          newSelectedTabs.add(tabId);
        } else {
          newSelectedTabs.delete(tabId);
        }
        setSelectedTabCount(newSelectedTabs.size);
        return newSelectedTabs;
      });

      return updatedSelectedItems;
    });
  };

  const userId = auth.currentUser?.uid;

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "searches" }],
      storeAs: `mySearches`,
    },
  ]);

  const filteredSavedSearches = () => {
    let saved = Object.keys(mySearches || {})
      .map((key) => (mySearches ? { ...mySearches[key], id: key } : null))
      .filter((item) => item !== null);
    if (isNCAA) {
      saved = saved.filter((item) => item.ncaaPortal);
    }

    return saved;
  };

  const tabs = [
    {
      id: "institution",
      title: "Institution",
      body: (
        <InstitutionDropdown
          value={filters.institution}
          onSelect={(option: { id: string; institution: string } | null) => {
            updateFilters((draft) => {
              draft.institution = option?.institution || "";
            });
            handleTabSelection(
              "institution",
              option?.institution === initialFilters.institution
            );
          }}
          options={institutions || []}
          placeHolder="Institutions"
        />
      ),
    },
    {
      id: "graduate",
      title: "Graduate",
      body: (
        <MUISwitch
          checked={filters.graduate}
          onChange={() => {
            updateFilters((draft) => {
              draft.graduate = !filters.graduate;
            });
            handleTabSelection(
              "graduate",
              filters.graduate !== initialFilters.graduate
            );
          }}
        />
      ),
    },
    {
      id: "mtr",
      title: "MTR",
      body: (
        <MUISwitch
          checked={filters.mtr}
          onChange={() => {
            updateFilters((draft) => {
              draft.mtr = !filters.mtr;
            });
            handleTabSelection("mtr", filters.mtr !== initialFilters.mtr);
          }}
        />
      ),
    },
    {
      id: "transferStatus",
      title: "Transfer Status",
      body: (
        <ListSelector
          id="transferStatus"
          list={TRANSFER_STATUS}
          filters={filters}
          updateFilters={updateFilters}
          onTabSelect={(wasSelected) =>
            handleTabSelection("transferStatus", wasSelected)
          }
        />
      ),
    },
    {
      id: "conference",
      title: "Conference",
      body: (
        <ConferenceDropwdown
          selectItem={(item) => {
            const selectedConference = item[0] || "";
            updateFilters((draft) => {
              draft.conference = selectedConference;
            });
            handleTabSelection(
              "conference",
              selectedConference === initialFilters.conference
            );
          }}
          allowAll={true}
        />
      ),
    },
    {
      id: "division",
      title: "Division",
      body: (
        <ListSelector
          id="division"
          list={DIVISIONS}
          filters={filters}
          updateFilters={updateFilters}
          onTabSelect={(wasSelected) =>
            handleTabSelection("division", wasSelected)
          }
        />
      ),
    },
    {
      id: "titlePlayerInfo",
      title: "PLAYER INFOMATION",
      body: null,
    },
    {
      id: "positions",
      title: "Position*",
      body: (
        <PositionDropdownNcaa
          selectPosition={(pos) => {
            setSelectedPositions(pos);

            updateFilters((draft) => {
              draft.positions = pos;
            });
            if (pos.length > 0 && !isPositionsSelected) {
              handleTabSelection("positions", false);
              setIsPositionsSelected(true);
            } else if (pos.length === 0 && isPositionsSelected) {
              handleTabSelection("positions", true);
              setIsPositionsSelected(false);
            }
          }}
        />
      ),
    },
    {
      id: "year",
      title: "",
      body: (
        <ListYear
          filters={filters}
          updateFilters={updateFilters}
          handleTabSelection={handleTabSelection}
          selectedTabItems={selectedTabItems}
        />
      ),
    },
    {
      id: "level",
      title: "",
      body: (
        <Level
          value={filters.level}
          onChange={(level) => {
            updateFilters((draft) => {
              draft.level = level;
            });
          }}
          handleTabSelection={handleTabSelection}
          selectedTabItems={selectedTabItems}
        />
      ),
    },
    {
      id: "bats",
      title: "",
      body: (
        <Bats
          filters={filters}
          updateFilters={updateFilters}
          handleTabSelection={handleTabSelection}
        />
      ),
    },
    {
      id: "throws",
      title: "",
      body: (
        <Throws
          filters={filters}
          updateFilters={updateFilters}
          handleTabSelection={handleTabSelection}
        />
      ),
    },
    {
      id: "pitchingStats",
      title: "",
      body: (
        <PitchingStats
          filters={filters}
          updateFilters={updateFilters}
          handleTabSelection={handleTabSelection}
        />
      ),
    },
    {
      id: "battingStats",
      title: "",
      body: (
        <BattingStats
          filters={filters}
          updateFilters={updateFilters}
          handleTabSelection={handleTabSelection}
        />
      ),
    },
  ] as { id: FilterId; title: string; body: ReactNode }[];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const filtersFromUrl = {};

    // Parse URL parameters into filters object
    params.forEach((value, key) => {
      if (
        [
          "year",
          "conference",
          "division",
          "institution",
          "graduate",
          "mtr",
          "transferStatus",
        ].includes(key)
      ) {
        if (key === "division") {
          filtersFromUrl[key] = value.split(",");
        } else if (key === "transferStatus") {
          // Ensure transferStatus is always an array
          filtersFromUrl[key] = value.includes(",")
            ? value.split(",")
            : [value];
        } else if (["graduate", "mtr"].includes(key)) {
          filtersFromUrl[key] = value === "true";
        } else if (key === "year") {
          filtersFromUrl[key] = parseInt(value);
        } else {
          filtersFromUrl[key] = value;
        }
      }
    });

    // Remove filterPortal parameter if exists
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.delete("filterPortal");

    // Only update filters if URL has parameters
    if (Object.keys(filtersFromUrl).length > 0) {
      updateFilters(() => ({
        ...initialFilters,
        ...filtersFromUrl,
      }));

      // Update selected tabs
      let tabCount = 0;
      const newSelectedTabs = new Set<FilterId>();
      const newSelectedTabItems = { ...selectedTabItems };

      Object.entries(filtersFromUrl).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value) && value.length > 0) {
            newSelectedTabItems[key as FilterId] = value.length;
            newSelectedTabs.add(key as FilterId);
            tabCount++;
          } else if (typeof value === "boolean" && value) {
            newSelectedTabItems[key as FilterId] = 1;
            newSelectedTabs.add(key as FilterId);
            tabCount++;
          } else if (value) {
            newSelectedTabItems[key as FilterId] = 1;
            newSelectedTabs.add(key as FilterId);
            tabCount++;
          }
        }
      });

      setSelectedTabItems(newSelectedTabItems);
      setSelectedTabs(newSelectedTabs);
      setSelectedTabCount(tabCount);
      setFiltersApplied(true);
      setShowFilterIcon(true);
    }
  }, []);

  useEffect(() => {
    if (selectedPositions.length === 0) {
      setShowWarning(false);
      return;
    }

    const pitcherPositions = ["RHP", "LHP"];

    const hasPitcher = selectedPositions.some((position) =>
      pitcherPositions.includes(position)
    );

    const hasNonPitcher = selectedPositions.some(
      (position) => !pitcherPositions.includes(position)
    );

    if (hasPitcher && hasNonPitcher) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [selectedPositions]);

  return (
    <>
      {!showAll ? (
        <div>
          <p
            style={{
              color: "white",
              fontWeight: "bold",
              marginTop: 20,
              fontSize: 24,
            }}
          >
            TRANSFER INFOMATION
          </p>
          <div className="tabContainer">
            {tabs.map(({ id, title, body }) => (
              <div key={id} className="tabContent">
                {title === "PLAYER INFOMATION" ? (
                  <p
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      marginTop: 40,
                      fontSize: 24,
                    }}
                  >
                    PLAYER INFOMATION
                  </p>
                ) : (
                  <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>
                    {title}
                  </h4>
                )}

                {body}
              </div>
            ))}
          </div>
          <div style={{ marginTop: "20px" }}>
            <button
              style={{
                backgroundColor: "white",
                color: "#003366",
                padding: 4,
                border: "2px solid white",
                width: "20%",
                borderRadius: 8,
                cursor: "pointer",
              }}
              onClick={resetAllFilters}
            >
              Reset Filters
            </button>
            {selectedTabCount > 0 && (
              <button
                style={{
                  backgroundColor: "white",
                  color: "#003366",
                  padding: 4,
                  border: "2px solid white",
                  width: "20%",
                  borderRadius: 8,
                  cursor: "pointer",
                  marginLeft: 20,
                  opacity: !canFilterPlayerInfomation() ? 0.5 : 1,
                }}
                disabled={!canFilterPlayerInfomation()}
                onClick={handleApplyFilters}
              >
                Apply Filters ({selectedTabCount})
              </button>
            )}
          </div>
        </div>
      ) : (
        <PortalResult
          showWithoutFilter={true}
          limit={true}
          filters={filters}
          setShowFilterIcon={setShowFilterIcon}
        />
      )}
      {isNCAA && showAdvancedOptions && (
        <div
          style={{
            width: "100%",
            marginLeft: "5%",
            height: 1,
            backgroundColor: "#fff",
            marginTop: 40,
            marginBottom: 25,
          }}
        />
      )}
      {isNCAA && showAdvancedOptions && (
        <div>
          <div
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyItems: "center",
              paddingLeft: 20,
              paddingRight: 20,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h4
                style={{ fontWeight: 300, fontSize: 20, color: colors.white }}
              >
                MY SAVED SEARCHES
              </h4>
              <Button
                variant="secondary"
                style={{
                  marginTop: -12,
                  color: "white",
                  fontSize: 30,
                  backgroundColor: "transparent",
                  padding: 6,
                  borderWidth: 0,
                  boxShadow: "none",
                }}
                onClick={() => setShowSaved(!showSaved)}
              >
                {showSaved ? <AiFillCaretDown /> : <AiFillCaretRight />}
              </Button>
            </div>
          </div>
          {showSaved && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              {filteredSavedSearches().map((item, index) =>
                item && item.id && item.name ? (
                  <div
                    key={index}
                    style={{
                      padding: 8,
                      backgroundColor: colors.white,
                      color: colors.accent,
                      borderRadius: 5,
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                      width: "40%",
                    }}
                    onClick={() => {
                      updateFilters(() => item.query);
                      const newSelectedTabItems: Record<FilterId, any> = {
                        conference: 0,
                        division: 0,
                        institution: 0,
                        graduate: 0,
                        mtr: 0,
                        transferStatus: 0,
                        level: 0,
                        year: 0,
                        bats: 0,
                        throws: 0,
                        selectedYears: 0,
                        w: 0,
                        l: 0,
                        wp: 0,
                        era: 0,
                        ra: 0,
                        gs: 0,
                        gf: 0,
                        cg: 0,
                        sho: 0,
                        sv: 0,
                        ip: 0,
                        h: 0,
                        r: 0,
                        er: 0,
                        hr: 0,
                        g: 0,
                        bb: 0,
                        ibb: 0,
                        wlpct: 0,
                        so: 0,
                        yearstats: 0,
                        positions: 0,
                        batting_g: 0,
                        batting_pa: 0,
                        batting_ab: 0,
                        batting_r: 0,
                        batting_h: 0,
                        batting_2b: 0,
                        batting_3b: 0,
                        batting_hr: 0,
                        batting_rbi: 0,
                        batting_sb: 0,
                        batting_cs: 0,
                        batting_bb: 0,
                        batting_so: 0,
                        batting_avg: 0,
                        batting_obp: 0,
                        batting_slg: 0,
                        batting_ops: 0,
                        batting_tb: 0,
                        batting_gdp: 0,
                        batting_hbp: 0,
                        batting_sh: 0,
                        batting_sf: 0,
                        batting_ibb: 0,
                      };
                      let tabCount = 0;
                      Object.entries(initialFilters).forEach(
                        ([key, initialValue]) => {
                          const savedValue = item.query[key];
                          const isDifferent = Array.isArray(initialValue)
                            ? JSON.stringify(initialValue) !==
                            JSON.stringify(savedValue)
                            : initialValue !== savedValue;
                          if (isDifferent) {
                            newSelectedTabItems[key as FilterId] =
                              Array.isArray(savedValue)
                                ? savedValue.length
                                : savedValue
                                  ? 1
                                  : 0;
                            tabCount++;
                          }
                        }
                      );
                      setSelectedTabItems(newSelectedTabItems);
                      setSelectedTabCount(tabCount);
                      setFiltersApplied(tabCount > 0);
                    }}
                  >
                    {/* Display Item Name and Date */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* Name of the item */}
                      <p style={{ fontWeight: "bold" }}>{item.name}</p>
                      {/* Creation Date */}
                      <p style={{ fontStyle: "italic", opacity: 0.7 }}>
                        {moment(
                          item.timeCreated
                            ? new Date(item.timeCreated.seconds * 1000)
                            : new Date()
                        ).format("M/D")}
                      </p>
                    </div>

                    <div
                      style={{
                        flexDirection: "row",
                        justifyContent: "end",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <UnderlineButton
                        color={colors.accent}
                        text={"edit"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditSavedSearch(item);
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div key={index} />
                )
              )}
            </div>
          )}
        </div>
      )}
      <EditSavedSearchModal
        show={editSavedSearch !== null}
        savedSearch={editSavedSearch}
        onHide={() => setEditSavedSearch(null)}
        title={"Rename NCAA Saved Search"}
        ncaaPortal={true}
      />
      <PopupWarning
        show={showWarning}
        message="Both position player and pitcher stats will be incorporated your preferred metrics"
        onClose={() => {
          setShowWarning(false);
        }}
      />
    </>
  );
}

/*****************************************************************************
 * Helper Components
 *****************************************************************************/
function ListSelector({ id, list, filters, updateFilters, onTabSelect }) {
  const handleOptionSelect = (item) => {
    updateFilters((draft) => {
      const filterList = draft[id];
      const newFilterList = filterList.includes(item)
        ? filterList.filter((i) => i !== item)
        : [...filterList, item];
      draft[id] = newFilterList;
    });
    onTabSelect(filters[id].includes(item));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {list.map((item, index) => (
        <button
          key={index}
          onClick={() => handleOptionSelect(item)}
          style={{
            backgroundColor: filters[id].includes(item)
              ? "white"
              : "transparent",
            color: filters[id].includes(item) ? "#003366" : "#fff",
            padding: 4,
            border: filters[id].includes(item)
              ? "2px solid transparent"
              : "2px solid white",
            width: "30%",
            borderRadius: 8,
            marginBottom: 8,
          }}
        >
          {item}
        </button>
      ))}
    </div>
  );
}
