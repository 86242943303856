import React, { useMemo, FC } from "react";
import Select from "react-select";
import colors from "constants/colors";

interface PositionProps {
  handlePositionSelect: (position: string[]) => void;
  position: string[];
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 200,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

const Position: FC<PositionProps> = ({ handlePositionSelect, position }) => {
  const options = useMemo(() => {
    let base = ["RHP", "LHP", "C", "1B", "2B", "SS", "3B", "LF", "CF", "RF"];
    return base.map((item) => ({ value: item, label: item }));
  }, []);

  const onChange = (items) => {
    handlePositionSelect(items.map((item) => item.value));
  };

  const value = position.map((r) => ({ value: r, label: r }));

  return (
    <div>
      <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>Position</h4>
      <Select
        options={options}
        isMulti={true}
        isClearable={false}
        onChange={onChange}
        styles={customStyles}
        value={value}
        placeholder="Position..."
      />
    </div>
  );
};

export default Position;
