import React from "react";
import { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";

const Bats = ({ filters, updateFilters, handleTabSelection }) => {
  const [isOpen, setIsOpen] = useState(false);
  const bats = ["R", "L", "S"];

  const handleBatSelect = (bat: string) => {
    if (filters.bats === bat) {
      updateFilters((draft) => {
        draft.bats = "";
      });
      handleTabSelection("bats", true);
    } else {
      const wasEmpty = filters.bats === "";
      updateFilters((draft) => {
        draft.bats = bat;
      });
      if (wasEmpty) {
        handleTabSelection("bats", false);
      }
    }
  };

  const checkboxStyle = (isChecked: boolean) => ({
    appearance: "none" as const,
    WebkitAppearance: "none" as const,
    width: "16px",
    height: "16px",
    border: isChecked ? "2px solid #4285f4" : "2px solid white",
    borderRadius: "3px",
    backgroundColor: isChecked ? "#4285f4" : "transparent",
    position: "relative" as const,
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          color: "white",
          fontSize: 24,
          alignItems: "center",
          gap: 10,
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <p>Bats</p>
        <div
          style={{
            transform: `rotate(${isOpen ? 0 : -90}deg)`,
            transition: "transform 0.1s ease-in-out",
          }}
        >
          <IoIosArrowDown />
        </div>
      </div>

      {isOpen && (
        <div style={{ marginTop: 10, paddingLeft: 20 }}>
          {bats.map((bat) => {
            const isChecked = filters.bats === bat;
            return (
              <div
                key={bat}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  marginBottom: 8,
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={() => handleBatSelect(bat)}
              >
                <div style={checkboxStyle(isChecked)}>
                  {isChecked && (
                    <div
                      style={{
                        width: "5px",
                        height: "9px",
                        borderRight: "2px solid white",
                        borderBottom: "2px solid white",
                        transform: "rotate(45deg)",
                        marginTop: "-2px",
                      }}
                    />
                  )}
                </div>
                <span style={{ fontSize: 16 }}>{bat}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Bats;
