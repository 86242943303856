const BASE_URL = "https://tara-proxy.herokuapp.com";
const ES_URL =
  "https://822d72960b3143a2aa8946b58375a7ef.us-east-1.aws.found.io:9243/";
const ES_CREDENTIALS = "elastic:sbiAbeKXR7WeGjL7YMmKXduB";

const getHeaders = () => {
  const headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(ES_CREDENTIALS));
  headers.append("Content-Type", "application/json");
  return headers;
};

export const fetchRegionsFromES = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}/${ES_URL}/junior_colleges/_search`,
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          size: 0,
          aggs: {
            unique_regions: {
              terms: {
                field: "region",
                order: { _key: "asc" },
                size: 1000,
              },
            },
          },
        }),
      }
    );

    const data = await response.json();
    if (data.aggregations?.unique_regions?.buckets) {
      return data.aggregations.unique_regions.buckets.map((bucket) => ({
        value: bucket.key,
        label: bucket.key,
      }));
    }
    return [];
  } catch (error) {
    console.error("Error fetching regions:", error);
    return [];
  }
};

export const fetchYearsFromES = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}/${ES_URL}/junior_colleges/_search`,
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          size: 0,
          aggs: {
            unique_years: {
              terms: {
                field: "year",
                order: { _key: "desc" },
                size: 100,
              },
            },
          },
        }),
      }
    );

    const data = await response.json();
    if (data.aggregations?.unique_years?.buckets) {
      return data.aggregations.unique_years.buckets.map((bucket) =>
        bucket.key.toString()
      );
    }
    return [];
  } catch (error) {
    console.error("Error fetching years:", error);
    return [];
  }
};

export const fetchTeamsFromES = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}/${ES_URL}/junior_colleges/_search`,
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          size: 0,
          aggs: {
            unique_teams: {
              terms: {
                field: "team",
                order: { _key: "asc" },
                size: 1000,
              },
            },
          },
        }),
      }
    );

    const data = await response.json();
    if (data.aggregations?.unique_teams?.buckets) {
      return data.aggregations.unique_teams.buckets.map((bucket) => ({
        value: bucket.key,
        label: bucket.key,
      }));
    }
    return [];
  } catch (error) {
    console.error("Error fetching teams:", error);
    return [];
  }
};

export const fetchHometownsFromES = async () => {
  try {
    const response = await fetch(
      `${BASE_URL}/${ES_URL}/junior_colleges/_search`,
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          size: 0,
          aggs: {
            unique_hometowns: {
              terms: {
                field: "hometown.keyword",
                order: { _key: "asc" },
                size: 5000,
              },
            },
          },
        }),
      }
    );

    const data = await response.json();
    if (data.aggregations?.unique_hometowns?.buckets) {
      return data.aggregations.unique_hometowns.buckets.map((bucket) => ({
        value: bucket.key,
        label: bucket.key,
      }));
    }
    return [];
  } catch (error) {
    console.error("Error fetching hometowns:", error);
    return [];
  }
};

export const convertToQueryBody = (filters: any, pageSize: number) => {
  const must: any[] = [];
  const pitchingStatsMust: any[] = [];
  const battingStatsMust: any[] = [];

  const divisionMapping = {
    "1": "Division I",
    "2": "Division II",
    "3": "Division III",
  };

  if (filters.league) {
    must.push({
      match: {
        league: filters.league,
      },
    });
  }

  if (Array.isArray(filters.division) && filters.division.length > 0) {
    must.push({
      bool: {
        should: filters.division.map((div) => ({
          match: {
            division: divisionMapping[div],
          },
        })),
      },
    });
  }

  if (Array.isArray(filters.region) && filters.region.length > 0) {
    must.push({
      bool: {
        should: filters.region.map((reg) => ({
          match: { region: reg },
        })),
      },
    });
  }

  if (Array.isArray(filters.year) && filters.year.length > 0) {
    must.push({
      bool: {
        should: filters.year.map((year) => ({
          match: { year: year },
        })),
      },
    });
  }

  if (Array.isArray(filters.school) && filters.school.length > 0) {
    must.push({
      bool: {
        should: filters.school.map((school) => ({
          match: { team: school },
        })),
      },
    });
  }

  if (Array.isArray(filters.homeTown) && filters.homeTown.length > 0) {
    must.push({
      bool: {
        should: filters.homeTown.map((town) => ({
          match_phrase: { hometown: town },
        })),
      },
    });
  }

  if (filters.height.active) {
    must.push({
      range: {
        height_calculated: { gte: filters.height.min, lte: filters.height.max },
      },
    });
  }

  if (filters.weight.active) {
    must.push({
      range: { weight: { gte: filters.weight.min, lte: filters.weight.max } },
    });
  }

  if (Array.isArray(filters.position) && filters.position.length > 0) {
    must.push({
      bool: {
        must: filters.position.map((pos) => ({
          term: {
            "position.keyword": pos,
          },
        })),
      },
    });
  }

  if (filters.bat) {
    must.push({
      match: { bats: filters.bat },
    });
  }

  if (filters.throw) {
    must.push({
      match: { throws: filters.throw },
    });
  }

  // Handle pitching stats in nested query
  const pitchingStatsMapping = {
    w: "wins",
    l: "losses",
    era: "era",
    g: "games",
    gs: "games-started",
    cg: "complete-games",
    sho: "shutouts",
    sv: "saves",
    ip: "innings-pitched",
    h: "hits-allowed",
    r: "runs-allowed",
    er: "earned-runs",
    hr: "home-runs-allowed",
    bb: "walks",
    so: "strikeouts",
    hbp: "hit-batters",
    whip: "whip",
    ba: "batting-average-against",
    "2b": "doubles-allowed",
    "3b": "triples-allowed",
    sf: "sacrifice-flies-allowed",
    sh: "sacrifice-hits-allowed",
    wp: "wild-pitches",
    bk: "balks",
    "k/9": "strikeouts-per-game",
  };

  const battingStatsMapping = {
    batting_g: "games-played",
    batting_pa: "plate-appearances",
    batting_ab: "at-bats",
    batting_r: "runs",
    batting_h: "hits",
    batting_2b: "doubles",
    batting_3b: "triples",
    batting_hr: "home-runs",
    batting_rbi: "rbis",
    batting_sb: "stolen-bases",
    batting_cs: "caught-stealing",
    batting_bb: "walks",
    batting_so: "strikeouts",
    batting_avg: "avg",
    batting_obp: "obp",
    batting_slg: "slg",
  };

  Object.entries(battingStatsMapping).forEach(([key, fieldName]) => {
    if (filters[key]?.active) {
      const min = filters[key].min;
      const max = filters[key].max;

      if (min === 0) {
        battingStatsMust.push({
          bool: {
            should: [
              {
                bool: {
                  must_not: {
                    exists: { field: `batting_stats.${fieldName}` },
                  },
                },
              },
              {
                range: {
                  [`batting_stats.${fieldName}`]: {
                    gte: min,
                    lte: max,
                  },
                },
              },
            ],
          },
        });
      } else {
        battingStatsMust.push({
          range: {
            [`batting_stats.${fieldName}`]: {
              gte: min,
              lte: max,
            },
          },
        });
      }
    }
  });

  // Add active stat filters to pitching_stats nested query
  Object.entries(pitchingStatsMapping).forEach(([key, fieldName]) => {
    if (filters[key]?.active) {
      const min = filters[key].min;
      const max = filters[key].max;

      if (min === 0) {
        pitchingStatsMust.push({
          bool: {
            should: [
              {
                bool: {
                  must_not: {
                    exists: { field: `pitching_stats.${fieldName}` },
                  },
                },
              },
              {
                range: {
                  [`pitching_stats.${fieldName}`]: {
                    gte: min,
                    lte: max,
                  },
                },
              },
            ],
          },
        });
      } else {
        pitchingStatsMust.push({
          range: {
            [`pitching_stats.${fieldName}`]: {
              gte: min,
              lte: max,
            },
          },
        });
      }
    }
  });

  // Add nested query only if there are pitching stats filters
  if (pitchingStatsMust.length > 0) {
    must.push({
      nested: {
        path: "pitching_stats",
        query: {
          bool: {
            must: pitchingStatsMust,
          },
        },
      },
    });
  }

  if (battingStatsMust.length > 0) {
    must.push({
      nested: {
        path: "batting_stats",
        query: {
          bool: {
            must: battingStatsMust,
          },
        },
      },
    });
  }

  return {
    query: {
      bool: {
        must,
      },
    },
    size: pageSize,
  };
};

export const fetchJuniorCollegeData = async (
  filters: any,
  pageSize: number,
  fromIndex: number,
  sortOrder: string,
  sortColumn: string
) => {
  try {
    const finalSort = sortOrder || "desc";
    const finalSortField =
      sortColumn === "position"
        ? "position.keyword"
        : sortColumn === "hometown"
          ? "hometown.keyword"
          : sortColumn || "last_found";

    const queryBody = convertToQueryBody(filters, pageSize);

    const response = await fetch(
      `${BASE_URL}/${ES_URL}/junior_colleges/_search?size=${pageSize}&from=${fromIndex}&sort=${finalSortField}:${finalSort}`,
      {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(queryBody),
      }
    );

    const data = await response.json();
    return {
      data: data.hits.hits.map((hit: any) => hit._source),
      total: data.hits.total.value,
    };
  } catch (error) {
    console.error("Error fetching junior college data:", error);
    return { data: [], total: 0 };
  }
};
