import React, { FC, useEffect, useMemo, useState } from "react";
import colors from "constants/colors";
import Select from "react-select";
import { fetchTeamsFromES } from "../../../elasticsearchQueries";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 250,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

interface SchoolProps {
  handleSchoolSelect: (schools: string[]) => void;
  school: string[];
}

const ALL_SCHOOLS = ["Arizona Western College", "Arizona State University"];

const School: FC<SchoolProps> = ({ handleSchoolSelect, school }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getSchools = async () => {
      const schools = await fetchTeamsFromES();
      setOptions(schools);
    };
    getSchools();
  }, []);

  const onChange = (items) => {
    handleSchoolSelect(items.map((item) => item.value));
  };

  const value = school.map((r) => ({ value: r, label: r }));

  return (
    <div>
      <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>School</h4>
      <Select
        options={options}
        isMulti={true}
        isClearable={false}
        onChange={onChange}
        styles={customStyles}
        value={value}
        placeholder="School..."
      />
    </div>
  );
};

export default School;
