import React from "react";
import Stat from "./Stat";
import { IoIosArrowDown } from "react-icons/io";
import { MINS, STEPS } from "constants/search";
import { MAXESPITCHING } from "../AdvancedSearchPortal";

interface PitchingStatsProps {
  filters: any;
  updateFilters: any;
  handleTabSelection: any;
}

export const stats = [
  "G",
  "W",
  "L",
  "ERA",
  "GS",
  "CG",
  "SHO",
  "SV",
  "IP",
  "H",
  "R",
  "ER",
  "HR",
  "BB",
  "WP",
  "RA",
  "IBB",
  "WLPCT",
  "SO",
];

const PitchingStats = ({
  filters,
  updateFilters,
  handleTabSelection,
}: PitchingStatsProps) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          color: "white",
          fontSize: 24,
          alignItems: "center",
          gap: 10,
          cursor: "pointer",
          width: "fit-content",
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <p>PITCHING STATS</p>
        <div
          style={{
            transform: `rotate(${isExpanded ? 0 : -90}deg)`,
            transition: "transform 0.1s ease-in-out",
          }}
        >
          <IoIosArrowDown />
        </div>
      </div>
      {isExpanded &&
        stats.map((stat) => {
          return (
            <Stat
              units={
                stat === "Weight"
                  ? "lbs"
                  : stat === "Height"
                  ? "in"
                  : stat === "Academic Class"
                  ? "collegeClass"
                  : ""
              }
              type={filters[stat.toLowerCase()].type}
              valmax={filters[stat.toLowerCase()].max || MAXESPITCHING[stat]}
              valmin={filters[stat.toLowerCase()].min || MINS[stat]}
              setValues={(vals, type) => {
                updateFilters((draft) => {
                  draft[stat.toLowerCase()] = {
                    min: vals[0],
                    max: vals[1],
                    active: true,
                  };
                });
                handleTabSelection(stat.toLowerCase(), false);
              }}
              setType={(newType) => {
                updateFilters((draft) => {
                  draft[stat.toLowerCase()].type = newType;
                });
              }}
              min={MINS[stat]}
              max={MAXESPITCHING[stat]}
              step={STEPS[stat]}
              title={stat}
            />
          );
        })}
    </div>
  );
};

export default PitchingStats;
