import colors from "constants/colors";
import React, { useMemo } from "react";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 200,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

const PositionDropdownNcaa = ({ selectPosition }: { selectPosition: any }) => {
  const options = useMemo(() => {
    let base = [
      "RHP",
      "LHP",
      "1B",
      "2B",
      "SS",
      "3B",
      "C",
      "LF",
      "RF",
      "CF",
      "RP",
      "DH",
      "PH",
      "PR",
      "OF",
      "SP",
    ];
    return base.map((item) => ({ value: item, label: item }));
  }, ["ALL POSITIONS"]);

  const onChange = (items) => {
    selectPosition(items.map((item) => item.value));
  };

  return (
    <Select
      options={options}
      isMulti={true}
      isClearable={false}
      onChange={onChange}
      styles={customStyles}
      placeholder="Position..."
    />
  );
};

export default PositionDropdownNcaa;
