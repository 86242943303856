import React from "react";

const Level = ({ value, onChange, handleTabSelection, selectedTabItems }) => {
  const handleLevelChange = (level: string) => {
    if (level === value) {
      handleTabSelection("level", true);
    } else if (selectedTabItems.level === 0) {
      handleTabSelection("level", false);
    }

    const newLevel = level === value ? "" : level;

    onChange(newLevel);
  };

  return (
    <div style={{ margin: "1rem 0" }}>
      <p style={{ color: "white", fontSize: 24, marginBottom: "10px" }}>
        Level
      </p>
      <div style={{ display: "flex", gap: "8px" }}>
        <button
          style={{
            padding: "8px 24px",
            background: value === "ncaa" ? "white" : "transparent",
            color: value === "ncaa" ? "#1a365d" : "white",
            cursor: "pointer",
            borderRadius: "24px",
            transition: "all 0.2s ease",
            fontSize: "16px",
            border: value === "ncaa" ? "1px solid" : "1px solid white",
          }}
          onClick={() => handleLevelChange("ncaa")}
        >
          NCAA
        </button>
        <button
          style={{
            padding: "8px 24px",
            border: value === "ncaa" ? "1px solid" : "1px solid white",
            background: value === "summer" ? "white" : "transparent",
            color: value === "summer" ? "#1a365d" : "white",
            cursor: "pointer",
            borderRadius: "24px",
            transition: "all 0.2s ease",
            fontSize: "16px",
          }}
          onClick={() => handleLevelChange("summer")}
        >
          Summer
        </button>
      </div>
    </div>
  );
};

export default Level;
