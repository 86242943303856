import { MINS } from "constants/search";
import {
  MAXESBATTING,
  MAXESPITCHING,
} from "../../PortalSearch/AdvancedSearchPortal";

interface RangeFilter {
  min: number;
  max: number;
  active?: boolean;
  type?: string;
}

const defaultRangeFilter = (min: number, max: number): RangeFilter => ({
  min,
  max,
  active: false,
  type: "",
});

export const pitchingFields = [
  "g",
  "w",
  "l",
  "era",
  "gs",
  "cg",
  "sho",
  "sv",
  "sh",
  "ip",
  "h",
  "r",
  "er",
  "hr",
  "bb",
  "wp",
  "ra",
  "ibb",
  "sf",
  "so",
  "spg",
  "baa",
  "hbp",
  "2b",
  "3b",
  "whip",
] as const;

export const battingFields = [
  "batting_g",
  "batting_pa",
  "batting_ab",
  "bk",
  "batting_r",
  "batting_h",
  "batting_2b",
  "batting_3b",
  "batting_hr",
  "batting_rbi",
  "batting_sb",
  "batting_cs",
  "batting_bb",
  "batting_so",
  "batting_avg",
  "batting_obp",
  "batting_slg",
  "batting_tb",
  "batting_gdp",
  "batting_sh",
  "batting_sf",
  "batting_ibb",
] as const;

export const initialFilters = {
  league: "NJCAA",
  height: { min: 60, max: 84, active: false },
  weight: { min: 100, max: 400, active: false },
  school: [],
  homeTown: [],
  year: [],
  position: [],
  division: [],
  region: [],
  bat: "",
  throw: "",

  ...Object.fromEntries(
    pitchingFields.map((field) => [
      field,
      defaultRangeFilter(
        MINS[field.toUpperCase()] ?? 0,
        MAXESPITCHING[field.toUpperCase()]
      ),
    ])
  ),

  ...Object.fromEntries(
    battingFields.map((field) => {
      const key = field.replace("batting_", "").toUpperCase();
      return [field, defaultRangeFilter(0, MAXESBATTING[key])];
    })
  ),
};
