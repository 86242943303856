import React, { FC, useEffect, useState } from "react";
import colors from "constants/colors";
import Select from "react-select";
import { fetchHometownsFromES } from "../../../elasticsearchQueries";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 250,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

interface HomeTownProps {
  handleHomeTownSelect: (homeTown: string[]) => void;
  homeTown: string[];
}

const HomeTown: FC<HomeTownProps> = ({ handleHomeTownSelect, homeTown }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getHometowns = async () => {
      const hometowns = await fetchHometownsFromES();
      setOptions(hometowns);
    };
    getHometowns();
  }, []);

  const onChange = (items) => {
    handleHomeTownSelect(items.map((item) => item.value));
  };

  const value = homeTown.map((r) => ({ value: r, label: r }));

  return (
    <div>
      <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>Home Town</h4>
      <Select
        options={options}
        isMulti={true}
        isClearable={false}
        onChange={onChange}
        styles={customStyles}
        placeholder="Home Town..."
        value={value}
      />
    </div>
  );
};

export default HomeTown;
