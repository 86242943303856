import React, { useMemo, FC, useEffect, useState } from "react";
import Select from "react-select";
import colors from "constants/colors";
import { fetchRegionsFromES } from "../../../elasticsearchQueries";

interface RegionProps {
  handleRegionSelect: (region: string[]) => void;
  region: string[];
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 200,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

const Region: FC<RegionProps> = ({ handleRegionSelect, region }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getRegions = async () => {
      const regions = await fetchRegionsFromES();
      setOptions(regions);
    };
    getRegions();
  }, []);

  const onChange = (items) => {
    const newValues = items ? items.map((item) => item.value) : [];
    handleRegionSelect(newValues);
  };

  const value = region.map((r) => ({ value: r, label: r }));

  return (
    <div>
      <h4 style={{ margin: "10px 0 10px 0", color: "white" }}>Region</h4>
      <Select
        options={options}
        isMulti={true}
        isClearable={false}
        onChange={onChange}
        value={value}
        styles={customStyles}
        placeholder="Region..."
      />
    </div>
  );
};

export default Region;
